import React, { useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Stack, Paper, Typography, Box, Skeleton, Link } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { SourceIcon } from 'components/common'
import { useMakeRequest } from 'hooks'
import makeStyles from '@mui/styles/makeStyles'

interface SourceItemType {
  id: number
  uri: string
  user: {
    username: string
    id: number
  }
  show: {
    date: string
    slug: string
  }
  song: {
    name: string
    slug: string
  }
  type: string
}

const RecentSources = () => {
  const classes = useStyles()
  const [getRecentSources, { data, loaded }] = useMakeRequest<SourceItemType[]>(
    [],
  )

  useEffect(() => {
    getRecentSources({ url: '/api/recent-sources' })
  }, [getRecentSources])

  const VoteItem = ({ uri, song, show, user, type }: SourceItemType) => {
    return (
      <li>
        <Paper elevation={1} className={classes.paper}>
          <Box className={classes.iconContainer}>
            <SourceIcon type={type} />
          </Box>
          <Box className={classes.linkContainer}>
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <Link
                href={uri}
                target="_blank"
                rel="noreferrer"
                className={classes.sourceLink}
              >
                {uri}
              </Link>
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Box className={classes.songShow}>
                <Link
                  component={RouterLink}
                  to={`/song/${song.slug}`}
                  className={classes.songLink}
                  color="primary"
                >
                  <Typography className={classes.song}>{song.name}</Typography>
                </Link>

                <Typography>&nbsp;-&nbsp;</Typography>
                <Link
                  component={RouterLink}
                  to={`/song/${song.slug}/${show.slug}`}
                  className={classes.showLink}
                  color="secondary"
                >
                  <Typography>{show.date}</Typography>
                </Link>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '3px',
                  flex: '1',
                  justifyContent: 'flex-end',
                  minWidth: '0',
                }}
                title={user.username}
              >
                <Link
                  component={RouterLink}
                  to={`/user/${user.id}`}
                  className={classes.userLink}
                >
                  <AccountCircleIcon
                    sx={{
                      fontSize: '16px',
                      marginRight: '3px',
                      fill: 'rgba(255,255,255, 0.5)',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontStyle: 'italic',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {user.username}
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </Paper>
      </li>
    )
  }

  return (
    <Box
      sx={{
        padding: '15px',
        borderRadius: '10px',
        background: '#353535',
      }}
    >
      <Typography
        component="h2"
        sx={{ fontWeight: 'bold', marginBottom: '10px' }}
      >
        Recent Sources
      </Typography>
      <Stack
        component="ul"
        spacing={1.5}
        sx={{ listStyleType: 'none', margin: '0', padding: '0' }}
      >
        {loaded
          ? data.map((d) => <VoteItem key={d.id} {...d} />)
          : Array.from({ length: 10 }, (v, i) => {
              return (
                <Skeleton
                  key={i}
                  variant="rectangular"
                  className={classes.skeletonItem}
                />
              )
            })}
      </Stack>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  userLink: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    textDecorationColor: 'rgba(255, 255, 255, 0.4)',
    minWidth: '0',
  },
  sourceLink: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    textDecorationColor: 'rgba(255, 255, 255, 0.4)',
  },
  songLink: {
    display: 'flex',
  },
  showLink: {
    display: 'flex',
  },
  paper: {
    display: 'flex',
    flex: 1,
    padding: '5px 10px',
    minWidth: '0',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 400px)': {
      display: 'none',
    },
  },
  linkContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minWidth: '0',
  },
  skeletonItem: {
    display: 'flex',
    borderRadius: '4px',
    height: '52px',
  },
  songShow: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '30px',
    '& p': {
      fontSize: '14px',
      whiteSpace: 'nowrap',
    },
  },
  song: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}))

export default RecentSources
