import React from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { useAppSelector } from 'hooks'

interface AdminRouteProps extends RouteProps {
  path: string
  component: React.ComponentType
  additionalProps?: any
}

const AdminRoute = ({
  path,
  component: Component,
  additionalProps,
}: AdminRouteProps) => {
  const is_admin = useAppSelector((state) => state.auth.user.is_admin)

  return (
    <Route
      path={path}
      render={(props) => {
        if (is_admin) {
          return <Component {...props} {...additionalProps} />
        }
        return <Redirect to="/" />
      }}
    />
  )
}

export default AdminRoute
