import { useEffect } from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import makeStyles from '@mui/styles/makeStyles'
import {
  Typography,
  Skeleton,
  Box,
  Paper,
  Link,
  Stack,
  Pagination,
} from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CommentIcon from '@mui/icons-material/Comment'
import { NoResults } from 'components/common'
import { useMakeRequest } from 'hooks'

interface CommentItemType {
  id: number
  message: string
  inserted_at: string
  show: {
    date: string
    slug: string
  }
  song: {
    name: string
    slug: string
  }
}

interface ProfileCommentsRespType {
  items: CommentItemType[]
  page: number
  total_pages: number
}

const ProfileComments = () => {
  const classes = useStyles()
  const { user_id } = useParams<{ user_id: string }>()
  const [
    getUserComments,
    {
      data: { items, page, total_pages },
      loaded,
      loading,
    },
    setState,
  ] = useMakeRequest<ProfileCommentsRespType>({
    items: [],
    page: 1,
    total_pages: 1,
  })

  useEffect(() => {
    getUserComments({
      url: `/api/users/profile-comments/${user_id}?page=${page}`,
    })
  }, [user_id, page, getUserComments])

  const changePage = (_e: React.ChangeEvent<unknown>, page: number) => {
    setState((state) => ({ ...state, data: { ...state.data, page } }))
  }

  const CommentItem = ({
    inserted_at,
    message,
    show,
    song,
  }: CommentItemType) => {
    const insertedTimeFormatted = format(new Date(`${inserted_at}Z`), 'PPpp')
    return (
      <Paper elevation={1} className={classes.commentBody}>
        <Box className={classes.iconContainer}>
          <CommentIcon color="primary" />
        </Box>
        <Box className={classes.commentItemBody}>
          <Box className={classes.commentHeader}>
            <Link
              to={`/song/${song.slug}`}
              component={RouterLink}
              color="primary"
              className={classes.songLink}
            >
              <Typography>{song.name}</Typography>
            </Link>
            <Typography>&nbsp;-&nbsp;</Typography>
            <Link
              to={`/song/${song.slug}/${show.slug}`}
              component={RouterLink}
              className={classes.showLink}
              color="secondary"
            >
              <Typography className={classes.date}>{show.date}</Typography>
            </Link>
            <Box
              className={classes.dateContainer}
              title={insertedTimeFormatted}
            >
              <AccessTimeIcon className={classes.insertedIcon} />
              <Typography className={classes.insertedTime}>
                {insertedTimeFormatted}
              </Typography>
            </Box>
          </Box>
          <Typography sx={{ fontSize: '14px' }}>{message}</Typography>
        </Box>
      </Paper>
    )
  }

  return (
    <Box className={classes.votesContainer}>
      <Box sx={{ display: 'flex', marginBottom: '10px' }}>
        <Typography variant="h5">Comments</Typography>
      </Box>
      <Box className={classes.commentContainer}>
        {loaded && !loading ? (
          items.map((d) => <CommentItem key={d.id} {...d} />)
        ) : (
          <Stack spacing={1.5} sx={{ margin: '0', padding: '0' }}>
            {Array.from({ length: 10 }, (v, i) => {
              return (
                <Skeleton
                  key={i}
                  variant="rectangular"
                  height={Math.max(52, Math.random() * 100)}
                  className={classes.skeletonItem}
                />
              )
            })}
          </Stack>
        )}
        {loaded && !items.length && <NoResults>No Comments</NoResults>}
      </Box>
      {total_pages > 1 && (
        <Box className={classes.paginationContainer}>
          <Pagination
            size="small"
            count={total_pages}
            page={page}
            onChange={changePage}
          />
        </Box>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme: any) => ({
  votesContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  skeletonItem: {
    display: 'flex',
    borderRadius: '4px',
    // height: '52px',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  },
  commentItemBody: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minWidth: '0',
  },
  songLink: {
    display: 'flex',
  },
  showLink: {
    display: 'flex',
  },
  commentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  commentBody: {
    display: 'flex',
    padding: '5px 10px',
    marginBottom: '12px',
  },
  commentHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    '& p': {
      fontSize: '12px',
    },
  },
  date: {
    marginRight: '10px',
  },
  childrenContainer: {
    marginLeft: '25px',
  },
  insertedAt: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(255,255,255, 0.5)',
    fontStyle: 'italic',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },

  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
    minWidth: '20px',
    flex: '1',
    justifyContent: 'flex-end',
  },
  insertedIcon: {
    fontSize: '14px',
    marginRight: '3px',
    fill: 'rgba(255,255,255, 0.5)',
  },
  insertedTime: {
    fontSize: '12px',
    fontStyle: 'italic',
    color: 'rgba(255,255,255, 0.5)',
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

export default ProfileComments
