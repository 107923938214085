import React, { useState } from 'react'
import { Box, TextField, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LoadingButton } from '@mui/lab'
import { useAppSelector, useMakeRequest } from 'hooks'
import { CommentType } from './Comments'

interface AddCommentProps {
  setCommentMode: React.Dispatch<React.SetStateAction<null | number>>
  show_song_id: number | null
  parent_id: number | null
  setComments: React.Dispatch<
    React.SetStateAction<{
      loading: boolean
      loaded: boolean
      data: CommentType[]
      error: {}
    }>
  >
}

const AddComment = ({
  setCommentMode,
  show_song_id,
  parent_id,
  setComments,
}: AddCommentProps) => {
  const classes = useStyles()
  const [comment, setComment] = useState('')
  const [createComment, { loading }] = useMakeRequest(undefined)
  const { id, username } = useAppSelector((state) => state.auth.user)

  const handleCommentInput = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setComment(e.target.value)

  const handleSubmitComment = async (e: React.FormEvent) => {
    e.preventDefault()
    const res = await createComment<CommentType>({
      method: 'post',
      url: '/api/show_songs/comments/new',
      data: {
        message: comment,
        show_song_id: show_song_id,
        user_id: id,
        parent_id,
        username,
      },
    })

    if (typeof (res as CommentType).id !== 'undefined') {
      setComments((state) => {
        if (res.parent_id) {
          return {
            ...state,
            data: state.data.map((c) =>
              c.id === res.parent_id
                ? { ...c, children: [res, ...c.children] }
                : c,
            ),
          }
        }
        return {
          ...state,
          data: [res, ...state.data],
        }
      })
      setCommentMode(null)
      setComment('')
    }
  }

  return (
    <Box
      component="form"
      autoComplete="off"
      noValidate
      onSubmit={handleSubmitComment}
      className={classes.commentForm}
    >
      <TextField
        id="outlined-multiline-flexible"
        label="Add comment"
        size="small"
        multiline
        minRows={2}
        fullWidth
        autoFocus
        value={comment}
        onChange={handleCommentInput}
      />
      <Box className={classes.buttonGroup}>
        <Button color="neutral" onClick={() => setCommentMode(null)}>
          Cancel
        </Button>
        <LoadingButton
          sx={{ ml: 1 }}
          // variant="outlined"
          loading={loading}
          // size="medium"
          color="primary"
          disableElevation
          type="submit"
        >
          Submit
        </LoadingButton>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  commentForm: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'flex-start',
    maxWidth: '500px',
    // [theme.breakpoints.up('md')]: {
    //   width: '50%',
    // },
  },
  buttonGroup: {
    display: 'flex',
    marginTop: '3px',
    justifyContent: 'flex-end',
  },
}))

export default AddComment
