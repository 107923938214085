import makeStyles from '@mui/styles/makeStyles'
import { Container } from '@mui/material'

interface PageContainerProps {
  children: React.ReactNode
  sx?: object
}

const PageContainer = ({ children, sx }: PageContainerProps) => {
  const classes = useStyles()

  return (
    <Container className={classes.container} sx={sx} maxWidth="lg">
      {children}
    </Container>
  )
}

const useStyles = makeStyles((theme?: any) => ({
  container: {
    paddingTop: '10px',
  },
}))

export default PageContainer
