import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  MenuItem,
  TextField,
  InputAdornment,
  CircularProgress,
  Autocomplete,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { alpha } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { useDebounce } from '../../hooks'

const ShowSearch = ({ setShow }) => {
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const debouncedQuery = useDebounce(query, 300)
  const classes = useStyles()

  const handleInput = (e) => {
    if (!!e) {
      setQuery(e.target.value || '')
    }
  }

  const getResultItem = (props, r, state) => {
    if (r.type === 'no-results') {
      return (
        <MenuItem
          {...props}
          key="no-show-results"
          component="li"
          className={classes.searchResult}
        >
          <div className="title">No results</div>
          <div className="no-results-msg">
            Couldn't find any shows for&nbsp;<i>"{query}"</i>
          </div>
        </MenuItem>
      )
    } else {
      return (
        <MenuItem
          {...props}
          key={r.slug}
          component="li"
          className={`${classes.searchResult} show`}
          onClick={() => handleSetShow(r)}
        >
          <div className="title">{r.date}</div>
          <div className="subtitle">{r.title}</div>
        </MenuItem>
      )
    }
  }

  const handleOnChange = (event, value, reason) => {
    if (event?.type === 'keydown' && reason === 'selectOption') {
      handleSetShow(value)
    } else {
      event.preventDefault()
      return false
    }
  }

  const handleSetShow = (show) => {
    setShow(show)
    setQuery('')
  }

  useEffect(() => {
    if (query === '') {
      setSearchResults([])
    }
  }, [query])

  useEffect(() => {
    let canceled = false
    if (debouncedQuery.length) {
      const fetchSearchResults = async () => {
        try {
          setLoading(true)
          const { data } = await axios.get(
            `/api/search/shows?q=${debouncedQuery}`,
          )
          if (!canceled) setSearchResults(data)
          setLoading(false)
        } catch (e) {
          console.log(e)
          setLoading(false)
        }
      }

      fetchSearchResults()
    }
    return () => {
      canceled = true
    }
  }, [debouncedQuery])

  return (
    <Autocomplete
      id="show-search"
      // disableClearable
      freeSolo
      inputValue={query}
      filterOptions={(o) => o}
      options={searchResults}
      loading={loading}
      onChange={handleOnChange}
      onInputChange={handleInput}
      classes={{
        root: classes.search,
        listbox: classes.listbox,
        // popper: classes.popper,
        // paper: classes.paper,
      }}
      getOptionLabel={(o) => o.date || 'No Results'}
      renderOption={getResultItem}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder="Search shows..."
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      size={20}
                      className={classes.loading}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )
      }}
    />
  )
}

export default ShowSearch

const useStyles = makeStyles((theme) => ({
  listbox: {
    padding: 0,
    width: '100%',
  },
  search: {
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.secondary.main, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.25),
    },
    marginTop: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      // marginLeft: theme.spacing(4),
      width: 'auto',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingIcon: {
    padding: theme.spacing(0, 2),
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    flex: 1,
  },
  inputInput: {
    padding: theme.spacing(2, 1, 2, 0),
    transition: theme.transitions.create('width'),
  },
  searchResult: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&.show': {
      borderLeft: `3px solid ${theme.palette.secondary.main}`,
      '&:nth-child(odd)': {
        borderLeftColor: theme.palette.secondary.light,
      },
    },
    '& .title': {
      fontWeight: 'bold',
    },
    '& .subtitle': {
      ...theme.typography.subtitle2,
      fontStyle: 'italic',
      color: theme.palette.grey[300],
      whiteSpace: 'normal',
    },
  },
}))
