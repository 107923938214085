import React from 'react'
import { makeStyles } from '@mui/styles'
import { PageContainer } from 'components/common'
import { Link as RouterLink } from 'react-router-dom'
import { Typography, Button, Grid, Box, Link } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'hooks'
import { openAuthDialog } from 'reducers/auth'
import RecentVotes from './RecentVotes'
import RecentSources from './RecentSources'
import RecentVersions from './RecentVersions'
import RecentComments from './RecentComments'

const Home = () => {
  const classes = useStyles()
  const username = useAppSelector((state) => state.auth.user.username)

  const dispatch = useAppDispatch()

  const welcomeMsg = username ? (
    `Welcome ${username}!`
  ) : (
    <>
      <Button onClick={() => dispatch(openAuthDialog('register'))}>
        Create an account
      </Button>{' '}
      to join in on the fun!
    </>
  )

  return (
    <PageContainer>
      <Typography className={classes.tagLine}>
        Explore the best live versions of Phish tunes
      </Typography>
      <Typography sx={{ textAlign: 'center' }}>{welcomeMsg}</Typography>
      <Box sx={{ margin: '5px 0' }}>
        <Link
          className={classes.allSongsLink}
          component={RouterLink}
          to="/all-songs"
        >
          Browse all songs
        </Link>
      </Box>
      <Grid container spacing={2} sx={{ paddingTop: '10px' }}>
        <Grid item xs={12} md={6}>
          <RecentVersions />
        </Grid>
        <Grid item xs={12} md={6}>
          <RecentVotes />
        </Grid>
        <Grid item xs={12} md={6}>
          <RecentSources />
        </Grid>
        <Grid item xs={12} md={6}>
          <RecentComments />
        </Grid>
      </Grid>
    </PageContainer>
  )
}

const useStyles = makeStyles((theme?: any) => ({
  allSongsLink: {
    fontSize: '1.5rem',
  },
  tagLine: {
    fontSize: '15px',
    fontStyle: 'italic',
    textAlign: 'center',
  },
}))

export default Home
