import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import axios from 'axios'
import {
  Popper,
  MenuItem,
  CircularProgress,
  TextField,
  InputAdornment,
  // useMediaQuery,
  Autocomplete,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { alpha } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { useDebounce } from '../../hooks'

const Search = () => {
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const debouncedQuery = useDebounce(query, 300)
  const classes = useStyles()
  const history = useHistory()
  // const theme = useTheme()
  // const matches = useMediaQuery(theme.breakpoints.down('sm'))

  const searchPopper = function (props) {
    // const responsive = matches ? { style: { width: '100%' } } : {}
    return <Popper {...props} placement="bottom-start" />
  }

  const handleInput = (e) => {
    if (!!e) {
      setQuery(e.target.value || '')
    }
  }

  const handleOnChange = (event, value, reason) => {
    if (event?.type === 'keydown' && reason === 'selectOption') {
      history.push(`/song/${value.slug}`)
    } else {
      event.preventDefault()
      return false
    }
  }

  const getResultItem = (props, r, state) => {
    if (r.type === 'no-results') {
      return (
        <MenuItem
          key="no-song-results"
          component="li"
          className={classes.searchResult}
        >
          <div className="title">No results</div>
          <div className="no-results-msg">
            Couldn't find any songs for&nbsp;<i>"{query}"</i>
          </div>
        </MenuItem>
      )
    }

    return (
      <MenuItem
        {...props}
        key={r.slug}
        component="li"
        className={classes.searchResultWrapper}
      >
        <Link className={`${classes.searchResult} song`} to={`/song/${r.slug}`}>
          <div className="title">{r.name}</div>
          <div className="subtitle">{r.artist}</div>
        </Link>
      </MenuItem>
    )
  }

  useEffect(() => {
    if (query === '') {
      setSearchResults([])
    }
  }, [query])

  useEffect(() => {
    let canceled = false
    if (debouncedQuery.length) {
      const fetchSearchResults = async () => {
        try {
          setLoading(true)
          const { data } = await axios.get(
            `/api/search/songs?q=${debouncedQuery}`,
          )
          if (!canceled) setSearchResults(data)
          setLoading(false)
        } catch (e) {
          console.log(e)
          setLoading(false)
        }
      }

      fetchSearchResults()
    }
    return () => {
      canceled = true
    }
  }, [debouncedQuery])

  return (
    <Autocomplete
      id="song-search"
      // disableClearable
      freeSolo
      PopperComponent={searchPopper}
      inputValue={query}
      filterOptions={(o) => o}
      options={searchResults}
      loading={loading}
      onChange={handleOnChange}
      onInputChange={handleInput}
      classes={{
        root: classes.search,
        listbox: classes.listbox,
        popper: classes.popper,
        paper: classes.paper,
      }}
      getOptionLabel={(o) => o.name || 'No Results'}
      renderOption={getResultItem}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder="Search songs…"
            size="small"
            type="search"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      size={20}
                      className={classes.loading}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )
      }}
    />
  )
}

export default Search

const useStyles = makeStyles((theme) => ({
  listbox: {
    padding: 0,
    width: '100%',
  },
  loading: {
    marginRight: '30px',
  },
  popper: {
    minWidth: 'fit-content',
    // width: '100%',
  },
  paper: {
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
  },
  search: {
    borderRadius: theme.shape.borderRadius,
    marginLeft: theme.spacing(2),
    '& .MuiFormControl-root': {
      backgroundColor: '#353535',
      borderRadius: '4px',
    },
    width: '100%',
    maxWidth: '350px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        backgroundColor: alpha(theme.palette.common.white, 0.1),
      },
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    padding: theme.spacing(0, 2),
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  searchResultWrapper: {
    padding: 0,
    '& .song': {
      borderLeft: `3px solid ${theme.palette.primary.main}`,
    },
    '&:nth-child(odd) .song': {
      borderLeftColor: theme.palette.primary.light,
    },
  },
  searchResult: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: 'white',
    display: 'flex',
    flex: 1,
    textDecoration: 'none',
    padding: '5px',
    paddingLeft: '10px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& .title': {
      fontWeight: 'bold',
    },
    '& .subtitle': {
      ...theme.typography.subtitle2,
      fontStyle: 'italic',
      color: theme.palette.grey[300],
      whiteSpace: 'normal',
    },
    '& .no-results-msg': {
      ...theme.typography.subtitle2,
      color: theme.palette.grey[300],
    },
  },
}))
