import {
  useLocation,
  Route,
  Redirect,
  // RouteComponentProps,
} from 'react-router-dom'
import { songInitials } from 'components/home/AllSongs'

interface AllSongsQueryRouteProps {
  path: string
  component: React.ComponentType<{}> // a class or function component which requires no props
}

const AllSongsQueryRoute = (props: AllSongsQueryRouteProps) => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const initial = query.get('i')

  return songInitials.some((i) => i === initial) ? (
    <Route {...props} />
  ) : (
    <Redirect to="/all-songs?i=A" />
  )
}

export default AllSongsQueryRoute
