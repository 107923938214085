import axios from 'axios'
import type { AxiosError } from 'axios'
import { useEffect, useState, useRef, useCallback } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { maybeSetToast } from 'reducers/globalToast'
import { setErrorToast } from 'reducers/globalToast'
import type { RootState, AppDispatch } from 'store'

export const useAppDispatch = () => useDispatch<AppDispatch>()

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export function useDebounce<Value>(value: Value, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export function useAltDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  const freshQuery = useRef(true)

  useEffect(() => {
    if (freshQuery.current && value.length > 2) {
      setDebouncedValue(value)
      freshQuery.current = false
    }

    const handler = setTimeout(() => {
      setDebouncedValue(value)
      freshQuery.current = true
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

interface ReqType {
  method?: 'get' | 'post' | 'put' | 'delete'
  url: string
  data?: any
}

interface ReqStateType<ReqReturnType = void> {
  loading: boolean
  loaded: boolean
  data: ReqReturnType
  error: AxiosError | any
}

type MakeRequestType = <ReqReturnType>(a: ReqType) => Promise<ReqReturnType>

export function useMakeRequest<ReqReturnType = void>(
  initialState: ReqReturnType,
): [
  MakeRequestType,
  ReqStateType<ReqReturnType>,
  React.Dispatch<
    React.SetStateAction<{
      loading: boolean
      loaded: boolean
      data: ReqReturnType
      error: {}
    }>
  >,
] {
  const [requestState, setRequestState] = useState({
    loading: false,
    loaded: false,
    data: initialState,
    error: {},
  })
  const controller = useRef<any>(null)
  const dispatch = useAppDispatch()
  const shouldSaveData = !!initialState

  useEffect(() => {
    return () => {
      controller.current?.abort()
    }
  }, [])

  const makeRequest = useCallback(
    async function <ReqReturnType>({
      method = 'get',
      url,
      data,
    }: ReqType): Promise<ReqReturnType> {
      try {
        controller.current?.abort()
        setRequestState((r) => ({ ...r, loading: true }))
        controller.current = new AbortController()
        const res = await axios.request({
          method,
          url,
          data,
          signal: controller.current.signal,
        })
        if (!!res?.data && shouldSaveData) {
          setRequestState((r) => ({ ...r, data: res.data }))
        }
        if (!!res?.data?.toast) {
          dispatch(maybeSetToast(res.data))
        }
        return res?.data
      } catch (e: AxiosError | any) {
        if (axios.isAxiosError(e) && e.response?.data) {
          if (e.response.data?.toast) {
            dispatch(setErrorToast(e.response?.data.message))
          }
          setRequestState((r) => ({ ...r, error: e.response?.data }))
          throw e
        }
        if (e instanceof Error && e.message) {
          dispatch(setErrorToast(e.message))
          throw e
        }
        throw e
      } finally {
        setRequestState((r) => ({ ...r, loading: false, loaded: true }))
      }
    },
    [dispatch, shouldSaveData],
  )

  return [makeRequest, requestState, setRequestState]
}
