import { Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useParams, useHistory } from 'react-router-dom'
import { useAppDispatch, useMakeRequest } from 'hooks'
import { PageContainer } from 'components/common'
import { setUserAsConfirmed } from 'reducers/auth'
import type { ResponseDataObject } from 'reducers/globalToast'

const ConfirmAccount = () => {
  const dispatch = useAppDispatch()
  const { token } = useParams<{ token: string }>()
  const [makeRequest, { loading }] = useMakeRequest(null)
  const history = useHistory()

  const submit = async () => {
    const { type } = await makeRequest<ResponseDataObject>({
      method: 'post',
      url: '/api/users/confirm',
      data: { token },
    })
    if (type === 'success' || type === 'info') {
      dispatch(setUserAsConfirmed())
      history.push('/')
    }
  }

  return (
    <PageContainer>
      <Typography variant="h2">Confirm Account</Typography>
      <LoadingButton
        loading={loading}
        onClick={submit}
        type="submit"
        variant="contained"
        sx={{ mt: 2 }}
      >
        Confirm My Account
      </LoadingButton>
    </PageContainer>
  )
}

export default ConfirmAccount
