import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Button, Box, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { passwordUpdateSuccess, UserType } from 'reducers/auth'
import { useAppDispatch, useMakeRequest } from 'hooks'

const ChangePassword = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const [editMode, setEditMode] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [updatePassword, { loading }] = useMakeRequest(null)

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setNewPassword(e.target.value)
  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setConfirmPassword(e.target.value)
  const handleCurrentPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setCurrentPassword(e.target.value)

  const submitNewPassword = async (e: React.FormEvent) => {
    try {
      e.preventDefault()
      const user = await updatePassword<UserType>({
        method: 'put',
        url: '/api/users/update-password',
        data: {
          password: newPassword,
          password_confirmation: confirmPassword,
          current_password: currentPassword,
        },
      })
      dispatch(passwordUpdateSuccess(user))
      setEditMode(false)
    } catch (e) {}
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '40px',
        alignItems: 'flex-start',
        paddingBottom: '20px',
      }}
    >
      {editMode ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
          component="form"
          onSubmit={submitNewPassword}
        >
          <TextField
            id="curr-password"
            label="Current Password"
            type="password"
            onChange={handleCurrentPasswordChange}
            value={currentPassword}
            inputProps={{ className: classes.input }}
            margin="normal"
            autoComplete="current-password"
            variant="outlined"
            size="small"
            sx={{ mt: 0, mb: 0 }}
            color="secondary"
          />
          <TextField
            id="new-password"
            label="New Password"
            type="password"
            onChange={handleNewPasswordChange}
            value={newPassword}
            inputProps={{ className: classes.input }}
            margin="normal"
            autoComplete="new-password"
            variant="outlined"
            size="small"
            sx={{ mt: '15px', mb: 0 }}
            color="secondary"
          />
          <Box
            sx={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}
          >
            <TextField
              id="confirm-password"
              label="Confirm New Password"
              type="password"
              onChange={handleConfirmPasswordChange}
              value={confirmPassword}
              inputProps={{ className: classes.input }}
              margin="normal"
              autoComplete="new-password"
              variant="outlined"
              size="small"
              sx={{ mt: 0, mb: 0 }}
              color="secondary"
            />
            <Box sx={{ ml: '5px', display: 'flex', alignItems: 'center' }}>
              <Button color="neutral" onClick={() => setEditMode(false)}>
                Cancel
              </Button>
              <LoadingButton color="secondary" type="submit" loading={loading}>
                Submit
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      ) : (
        <Button
          onClick={() => setEditMode(true)}
          color="secondary"
          variant="outlined"
        >
          Change Password
        </Button>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme?: any) => ({
  input: {
    '&:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 100px #3a3a3a inset',
    },
  },
}))

export default ChangePassword
