import { useState } from 'react'
import { Typography, TextField, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useParams, useHistory } from 'react-router-dom'
import { useMakeRequest, useAppDispatch } from 'hooks'
import { makeStyles } from '@mui/styles'
import { PageContainer } from 'components/common'
import { openAuthDialog } from 'reducers/auth'
import type { ResponseDataObject } from 'reducers/globalToast'

const ResetPassword = () => {
  const { token } = useParams<{ token: string }>()
  const [makeRequest, { loading }] = useMakeRequest(null)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [newPass, setNewPass] = useState('')
  const [confirmPass, setConfirmPass] = useState('')
  const classes = useStyles()

  const handleNewPassChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setNewPass(e.target.value)
  const handleConfirmPassChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setConfirmPass(e.target.value)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const { type } = await makeRequest<ResponseDataObject>({
      method: 'put',
      url: `/api/users/reset_password/${token}`,
      data: {
        user: {
          password: newPass,
          password_confirmation: confirmPass,
        },
      },
    })
    if (type === 'success') {
      dispatch(openAuthDialog())
      history.push('/')
    }
  }

  return (
    <PageContainer>
      <Typography variant="h2">Reset Password</Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          id="new-password"
          label="New Password"
          onChange={handleNewPassChange}
          type="password"
          margin="normal"
          autoComplete="new-password"
          variant="outlined"
          // fullWidth
        />
        <TextField
          id="confirm-password"
          label="Confirm Password"
          type="password"
          onChange={handleConfirmPassChange}
          margin="normal"
          autoComplete="new-password"
          variant="outlined"
          // fullWidth
        />
        <Box sx={{ display: 'flex', m: 1, ml: 0, mt: 2 }}>
          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            // sx={{ mr: 1 }}
          >
            Reset Password
          </LoadingButton>
        </Box>
      </form>
    </PageContainer>
  )
}

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
})

export default ResetPassword
