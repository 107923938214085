import { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Typography, Button } from '@mui/material'

import AddCommentIcon from '@mui/icons-material/AddComment'
import { useAppSelector, useAppDispatch, useMakeRequest } from 'hooks'
import { openAuthDialog } from 'reducers/auth'
import AddComment from './AddComment'
import Comment from './Comment'
import { NoResults } from 'components/common'

export interface CommentType {
  id: number
  message: string
  parent_id?: number
  inserted_at: string
  children: CommentType[]
  user: {
    username: string
    id: number
  }
}

interface CommentsProps {
  show_song_id: number | null
}

const Comments = ({ show_song_id }: CommentsProps) => {
  const classes = useStyles()
  const { email } = useAppSelector((state) => state.auth.user)
  const dispatch = useAppDispatch()
  const [commentMode, setCommentMode] = useState<number | null>(null)

  const [getComments, { data, loaded }, setComments] = useMakeRequest<
    CommentType[]
  >([])

  useEffect(() => {
    if (show_song_id) {
      getComments({ url: `/api/show_songs/${show_song_id}/comments` })
    }
  }, [getComments, show_song_id])

  const toAddComment = (id: CommentType['id']) => {
    if (email) {
      setCommentMode(id)
    } else {
      dispatch(openAuthDialog())
    }
  }

  return (
    <Box className={classes.commentsContainer}>
      <Typography variant="h5">Comments</Typography>
      {commentMode === 0 ? (
        <AddComment
          setCommentMode={setCommentMode}
          show_song_id={show_song_id}
          parent_id={null}
          setComments={setComments}
        />
      ) : (
        <Box sx={{ marginTop: '5px' }}>
          <Button
            startIcon={<AddCommentIcon />}
            onClick={() => toAddComment(0)}
            size="small"
          >
            Add Comment
          </Button>
        </Box>
      )}

      <Box className={classes.commentsSection}>
        {data.map((c) => (
          <Comment
            key={c.id}
            {...c}
            show_song_id={show_song_id}
            setCommentMode={setCommentMode}
            commentMode={commentMode}
            toAddComment={toAddComment}
            setComments={setComments}
          />
        ))}
        {loaded && !data.length && <NoResults>No Comments</NoResults>}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  commentsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  commentsSection: {
    display: 'flex',
    flexDirection: 'column',
    margin: '5px 5px 30px',
  },
}))

export default Comments
