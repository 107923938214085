import React, { useState } from 'react'
import {
  DialogTitle,
  TextField,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { makeStyles } from '@mui/styles'
import { useAppDispatch, useMakeRequest } from 'hooks'
import {
  closeAuthDialog,
  setAuthDialogView,
  loginSuccess,
  UserType,
} from 'reducers/auth'

const LoginDialog = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [loginUser, { loading }] = useMakeRequest(null)

  const handleSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault()
      const user = await loginUser<UserType>({
        method: 'post',
        url: '/api/users/log_in',
        data: {
          email,
          password,
          remember_me: rememberMe,
        },
      })
      dispatch(loginSuccess(user))
    } catch (e) {}
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value)
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value)
  const handleRememberMeChange = (
    _e: React.SyntheticEvent<Element, Event>,
    checked: boolean,
  ) => {
    setRememberMe(checked)
  }

  return (
    <>
      <DialogTitle sx={{ pb: 0, pl: 2 }}>Login</DialogTitle>
      <Box
        sx={{
          p: 2,
          pt: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <form onSubmit={handleSubmit}>
          <TextField
            id="email"
            label="Email"
            value={email}
            onChange={handleEmailChange}
            type="email"
            margin="normal"
            autoComplete="email"
            variant="outlined"
            fullWidth
          />
          <TextField
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            margin="normal"
            autoComplete="current-password"
            variant="outlined"
            fullWidth
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Keep me logged in for 60 days"
            onChange={handleRememberMeChange}
            checked={rememberMe}
          />
          <Box sx={{ display: 'flex', m: 1, ml: 0, mt: 2 }}>
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              sx={{ mr: 1 }}
              loading={loading}
            >
              Login
            </LoadingButton>
            <Button color="neutral" onClick={() => dispatch(closeAuthDialog())}>
              cancel
            </Button>
          </Box>
        </form>
        <Box sx={{ display: 'flex', m: 1, mt: 2 }}>
          <ButtonBase
            focusRipple
            className={classes.logRegButton}
            onClick={() => dispatch(setAuthDialogView('register'))}
          >
            Register
          </ButtonBase>
          <span className={classes.separator}>|</span>
          <ButtonBase
            focusRipple
            className={classes.logRegButton}
            onClick={() => dispatch(setAuthDialogView('forgot-password'))}
          >
            Forgot Password?
          </ButtonBase>
        </Box>
      </Box>
    </>
  )
}

const useStyles = makeStyles({
  logRegButton: {
    fontSize: '14px',
    '&:hover': {
      color: 'white',
      textDecoration: 'underline',
    },
  },
  separator: {
    margin: '0 5px',
  },
})

export default LoginDialog
