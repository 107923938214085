import React, { useState } from 'react'
import { DialogTitle, TextField, Box, Button, ButtonBase } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { makeStyles } from '@mui/styles'
import { useAppDispatch, useMakeRequest } from 'hooks'
import {
  closeAuthDialog,
  setAuthDialogView,
  registerSuccess,
  UserType,
} from 'reducers/auth'

const Register = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')
  const [registerUser, { loading }] = useMakeRequest(null)

  const handleSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault()
      const user = await registerUser<UserType>({
        method: 'post',
        url: '/api/users/register',
        data: {
          email,
          password,
          username,
        },
      })
      dispatch(registerSuccess(user))
    } catch (e) {}
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value)
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value)
  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setUsername(e.target.value)

  return (
    <>
      <DialogTitle sx={{ pb: 0, pl: 2 }}>Register</DialogTitle>
      <Box
        sx={{
          p: 2,
          pt: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <form onSubmit={handleSubmit}>
          <TextField
            id="screen_name"
            label="Screen Name"
            type="text"
            value={username}
            onChange={handleUsernameChange}
            color="secondary"
            inputProps={{ className: classes.input }}
            margin="normal"
            autoComplete="nickname"
            variant="outlined"
            fullWidth
            required
            helperText="This will be publicly viewable."
          />
          <TextField
            id="email"
            label="Email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            color="secondary"
            inputProps={{ className: classes.input }}
            margin="normal"
            autoComplete="email"
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            color="secondary"
            inputProps={{ className: classes.input }}
            margin="normal"
            autoComplete="new-password"
            variant="outlined"
            fullWidth
            required
          />
          <Box sx={{ display: 'flex', m: 1, ml: 0, mt: 2 }}>
            <LoadingButton
              type="submit"
              color="secondary"
              variant="contained"
              loading={loading}
              sx={{ mr: 1 }}
            >
              Register
            </LoadingButton>
            <Button color="neutral" onClick={() => dispatch(closeAuthDialog())}>
              cancel
            </Button>
          </Box>
        </form>
        <Box sx={{ display: 'flex', m: 1, mt: 2 }}>
          <ButtonBase
            focusRipple
            className={classes.logRegButton}
            onClick={() => dispatch(setAuthDialogView('login'))}
          >
            Login
          </ButtonBase>
          <span className={classes.separator}>|</span>
          <ButtonBase
            focusRipple
            className={classes.logRegButton}
            onClick={() => dispatch(setAuthDialogView('forgot-password'))}
          >
            Forgot Password?
          </ButtonBase>
        </Box>
      </Box>
    </>
  )
}

const useStyles = makeStyles({
  logRegButton: {
    fontSize: '14px',
    '&:hover': {
      color: 'white',
      textDecoration: 'underline',
    },
  },
  input: {
    '&:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 100px #3a3a3a inset',
    },
  },
  separator: {
    margin: '0 5px',
  },
})

export default Register
