import { AnyAction } from 'redux'
import { AppDispatch } from 'store'

const SET_GLOBAL_TOAST = 'SET_GLOBAL_TOAST'
const RESET_GLOBAL_TOAST = 'RESET_GLOBAL_TOAST'

type ToastType = 'error' | 'warning' | 'info' | 'success'

interface GlobalToastType {
  isOpen: boolean
  type: ToastType
  message: string
}

const initialState: GlobalToastType = {
  isOpen: false,
  type: 'info',
  message: '',
}

function globalToast(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SET_GLOBAL_TOAST:
      return {
        ...initialState,
        ...action.toastProps,
      }
    case RESET_GLOBAL_TOAST:
      return {
        ...state,
        isOpen: false,
      }
    default:
      return state
  }
}

export default globalToast

export function setToast(toastProps: { type: ToastType; message: string }) {
  return {
    type: SET_GLOBAL_TOAST,
    toastProps: {
      ...toastProps,
      isOpen: true,
    },
  }
}

export function setSuccessToast(message: string) {
  return setToast({
    type: 'success',
    message,
  })
}

export function setWarningToast(message: string) {
  return setToast({
    type: 'warning',
    message,
  })
}

export function setInfoToast(message: string) {
  return setToast({
    type: 'info',
    message,
  })
}

export function setErrorToast(message: string) {
  return setToast({
    type: 'error',
    message,
  })
}

export function closeToast() {
  return { type: RESET_GLOBAL_TOAST }
}

export interface ResponseDataObject {
  toast: boolean
  type: 'error' | 'info' | 'success' | 'warning'
  message: string
}

export interface ResponseObject {
  response: {
    data: ResponseDataObject
  }
}

export const maybeSetToast =
  ({ type, message }: ResponseDataObject) =>
  (dispatch: AppDispatch) => {
    dispatch(setToast({ type, message }))
  }
