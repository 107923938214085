import React, { useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import makeStyles from '@mui/styles/makeStyles'
import { Typography, Skeleton, Box } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { useMakeRequest } from 'hooks'
import Sources from './Sources'
import Comments from './comments/Comments'
import UpVote from './UpVote'
import { PageContainer, Breadcrumbs } from 'components/common'
import { SongType } from 'components/song/SongRouter'
import { getShowLocation } from 'functions'
export interface ShowSongType {
  show: {
    date: string
    city: string
    state: string
    country: string
    venue: string
  }
  show_song_id: number | null
  upvote_count: number
}

interface ShowSongProps {
  song: SongType
  songLoaded: boolean
}

const ShowSong = ({ song, songLoaded }: ShowSongProps) => {
  const classes = useStyles()
  const { showSlug, songSlug } =
    useParams<{ showSlug: string; songSlug: string }>()

  const [fetchShowSong, { data: showSong, loaded, error }, setShowSongState] =
    useMakeRequest<ShowSongType>({
      show: {
        date: '',
        city: '',
        state: '',
        country: '',
        venue: '',
      },
      show_song_id: null,
      upvote_count: 0,
    })
  const showDateFormatted =
    showSong.show.date && format(parseISO(showSong.show.date), 'MMM. d, yyyy')

  useEffect(() => {
    fetchShowSong({
      url: `/api/show_songs/details/${songSlug}/${showSlug}`,
    })
  }, [showSlug, songSlug, fetchShowSong])

  const setShowSongVotes = (upvote_count: ShowSongType['upvote_count']) =>
    setShowSongState((state) => ({
      ...state,
      data: { ...state.data, upvote_count },
    }))

  if (error?.type === 'error')
    return <div className={classes.error}>{error.message}</div>

  return (
    <PageContainer>
      <Helmet>
        {song.name && (
          <title>{`${showDateFormatted} - ${song.name} | phinest`}</title>
        )}
        <meta
          name="description"
          content={`Check out the sources for this version of ${song.name} from ${showDateFormatted} and join in on the discussion.`}
        />
      </Helmet>
      <Breadcrumbs>
        <Link to="/">Home</Link>
        <Link to={`/song/${songSlug}`}>{song.name}</Link>
        <Typography>{showDateFormatted}</Typography>
      </Breadcrumbs>
      <div className={classes.showSongContainer}>
        <Box className={classes.songHeaderContainer}>
          <Typography className={classes.name} variant="h4">
            {songLoaded ? song.name : <Skeleton width={300} />}
          </Typography>
        </Box>
        <Box className={classes.headerContainer}>
          <Box>
            <Typography className={classes.date} variant="h4">
              {loaded ? showDateFormatted : <Skeleton width={200} />}
            </Typography>
            <Typography variant="subtitle1">
              {loaded ? showSong.show.venue : <Skeleton width={180} />}
            </Typography>
            <Typography sx={{ fontStyle: 'italic' }} variant="subtitle1">
              {loaded ? (
                getShowLocation({
                  city: showSong.show.city,
                  state: showSong.show.state,
                  country: showSong.show.country,
                })
              ) : (
                <Skeleton width={180} />
              )}
            </Typography>
          </Box>
          <UpVote
            setShowSongVotes={setShowSongVotes}
            upvote_count={showSong.upvote_count}
            show_song_id={showSong.show_song_id}
          />
        </Box>
        <Sources show_song_id={showSong.show_song_id} />
        <Comments show_song_id={showSong.show_song_id} />
      </div>
    </PageContainer>
  )
}

export default ShowSong

const useStyles = makeStyles((theme: any) => ({
  showSongContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  songHeaderContainer: {
    display: 'flex',
    color: 'unset',
    textDecoration: 'none',
    flexDirection: 'column',
    paddingLeft: '10px',
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '20px',
    },
  },
  name: {
    fontWeight: theme.typography.fontWeightRegular,
    [theme.breakpoints.up('sm')]: {
      fontSize: '3em',
    },
  },
  date: {
    fontSize: '1.6em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2em',
    },
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0),
    padding: '0 10px',
    borderLeft: `5px solid ${theme.palette.secondary.main}`,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '20px',
    },
  },
  error: {
    margin: '10px',
  },
}))
