import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Typography, Skeleton, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PageContainer, Breadcrumbs } from 'components/common'
import ShowSongsList from './ShowSongsList'
import { format, parseISO } from 'date-fns'

// import isEmpty from 'ramda/src/isEmpty'
import { SongType } from './SongRouter'

interface SongProps {
  song: SongType
  loaded: boolean
}

const Song = ({ song, loaded }: SongProps) => {
  const classes = useStyles()

  return (
    <PageContainer>
      <Helmet>{song.name && <title>{`${song.name} | phinest`}</title>}</Helmet>
      <Breadcrumbs>
        <Link to="/">Home</Link>
        <Typography>{song.name}</Typography>
      </Breadcrumbs>
      <Box className={classes.headerContainer}>
        <Typography className={classes.name} variant="h4">
          {loaded ? song.name : <Skeleton width={300} />}
        </Typography>
        <Typography className={classes.artist} variant="subtitle1">
          {loaded ? `Written by: ${song.artist}` : <Skeleton width={120} />}
        </Typography>
        <Typography className={classes.debut} variant="subtitle1">
          {loaded ? (
            `Debut: ${format(parseISO(song.first_played), 'MMM. d, yyyy')}`
          ) : (
            <Skeleton width={140} />
          )}
        </Typography>
      </Box>
      <div className={classes.body}>
        <ShowSongsList />
      </div>
    </PageContainer>
  )
}

const useStyles = makeStyles((theme?: any) => ({
  headerContainer: {
    display: 'flex',
    color: 'unset',
    textDecoration: 'none',
    flexDirection: 'column',
    paddingLeft: '10px',
    paddingBottom: '5px',
    // border: `1px solid ${theme.palette.primary.main}`,
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    // borderRadius: '4px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '20px',
    },
  },
  name: {
    fontWeight: theme.typography.fontWeightRegular,
    [theme.breakpoints.up('sm')]: {
      fontSize: '3em',
    },
  },
  debut: {
    fontStyle: 'italic',
    color: theme.palette.grey[300],
  },
  artist: {
    color: theme.palette.grey[300],
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
  },
  error: {
    margin: '10px',
  },
  breadCrumb: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export default Song
