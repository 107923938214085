import React from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'hooks'
import { setWarningToast } from 'reducers/globalToast'

interface ProtectRouteProps extends RouteProps {
  path: string
  redirectPath: string
  component: React.ComponentType
  additionalProps?: any
}

const ProtectedRoute = ({
  path,
  redirectPath,
  component: Component,
  additionalProps,
}: ProtectRouteProps) => {
  const dispatch = useAppDispatch()
  const email = useAppSelector((state) => state.auth.user.email)

  return (
    <Route
      path={path}
      // render={(props) => <Component {...props} {...additionalProps} />}
      render={(props) => {
        if (email) {
          return <Component {...props} {...additionalProps} />
        }
        dispatch(setWarningToast('You must be logged in to access that page.'))
        return <Redirect to={redirectPath} />
      }}
    />
  )
}

export default ProtectedRoute
