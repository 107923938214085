import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Link,
  ListItemText,
  ListItemIcon,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import AccountCircle from '@mui/icons-material/AccountCircle'
import LoginIcon from '@mui/icons-material/Login'
import PersonIcon from '@mui/icons-material/Person'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import Divider from '@mui/material/Divider'
import Logo from 'media/phinest-white.svg'
import { useAppDispatch, useAppSelector, useMakeRequest } from 'hooks'
import { openAuthDialog, logoutSuccess } from 'reducers/auth'
import Search from './Search'

const TopBar = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useAppDispatch()
  const { email, id, is_admin } = useAppSelector((state) => state.auth.user)
  const classes = useStyles()
  const accountMenuOpen = Boolean(anchorEl)
  const [logoutUser] = useMakeRequest(null)

  const handleMenu = (event) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const logout = async () => {
    try {
      setAnchorEl(null)
      await logoutUser({ method: 'delete', url: '/api/users/log_out' })
      dispatch(logoutSuccess())
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar variant="dense">
          <Link
            to="/"
            sx={{ display: 'flex', alignItems: 'center' }}
            component={NavLink}
          >
            <img src={Logo} alt="phinest logo" className={classes.logo} />
          </Link>
          <Search />
          <div className={classes.menuContainer}>
            {email ? (
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                size="large"
              >
                <AccountCircle fontSize="large" titleAccess="Profile" />
              </IconButton>
            ) : (
              <IconButton
                edge="end"
                aria-label="login"
                aria-controls="menu-appbar"
                // aria-haspopup="true"
                onClick={() => dispatch(openAuthDialog())}
                color="inherit"
                size="large"
              >
                <LoginIcon fontSize="large" titleAccess="Login" />
              </IconButton>
            )}
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={accountMenuOpen}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => setAnchorEl(null)}
                to={`/user/${id}`}
                component={NavLink}
              >
                <ListItemIcon>
                  <PersonIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>My Profile</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => setAnchorEl(null)}
                to="/account-settings"
                component={NavLink}
              >
                <ListItemIcon>
                  <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Account Settings</ListItemText>
              </MenuItem>
              {is_admin && (
                <MenuItem
                  onClick={() => setAnchorEl(null)}
                  to="/admin"
                  component={NavLink}
                >
                  <ListItemIcon>
                    <AdminPanelSettingsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Admin</ListItemText>
                </MenuItem>
              )}
              <Divider />
              <MenuItem onClick={logout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
    </>
  )
}

export default TopBar

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundImage: 'none',
    backgroundColor: '#191919',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    height: '30px',
    width: 'auto',
    cursor: 'pointer',
    objectFit: 'cover',
    objectPosition: 'left',
    [theme.breakpoints.down('sm')]: {
      width: '38px',
    },
  },
  login: {
    // marginLeft: 'auto',
  },
  menuContainer: {
    marginLeft: 'auto',
  },
  offset: {
    // ...theme.mixins.toolbar,
    minHeight: '56px',
  },
}))
