import React from 'react'
import { PageContainer, BackButton } from 'components/common'
import { Typography } from '@mui/material'
import EditUsername from './EditUsername'
import ChangeEmail from './ChangeEmail'
import ChangePassword from './ChangePassword'
import ResendConfirmation from './ResendConfirmation'
import { useAppSelector } from 'hooks'

const Settings = () => {
  const user = useAppSelector((state) => state.auth.user)
  return (
    <PageContainer>
      <BackButton />
      <Typography variant="h4">Account Settings</Typography>
      {user.confirmed_at ? (
        <>
          <EditUsername />
          <ChangeEmail />
          <ChangePassword />
        </>
      ) : (
        <ResendConfirmation />
      )}
    </PageContainer>
  )
}

export default Settings
