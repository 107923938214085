import { useHistory } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Breadcrumbs, Button } from '@mui/material'

const BackButton = () => {
  const classes = useStyles()
  const { goBack } = useHistory()

  return (
    <Breadcrumbs>
      <Button
        color="neutral"
        className={classes.backButton}
        onClick={() => goBack()}
        startIcon={<ArrowBackIcon />}
      >
        Back
      </Button>
    </Breadcrumbs>
  )
}

const useStyles = makeStyles((theme: any) => ({
  backButton: {
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
}))

export default BackButton
