import React, { useEffect } from 'react'
import { openAuthDialog } from 'reducers/auth'
import { useAppSelector, useAppDispatch } from 'hooks'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useMakeRequest } from 'hooks'
import { Helmet } from 'react-helmet'
import {
  ListItem,
  Paper,
  List,
  Skeleton,
  Box,
  Pagination,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import ThumbUp from '@mui/icons-material/ThumbUp'
import makeStyles from '@mui/styles/makeStyles'
import { NoResults } from 'components/common'
import { getShowLocation } from 'functions'
import { SelectChangeEvent } from '@mui/material/Select'

interface ListShowSongType {
  id: number
  show: {
    id: number
    date: string
    slug: string
    city: string
    state: string
    country: string
    venue: string
  }
  song: {
    slug: string
  }
  upvote_count: number
}

const isTouch = navigator.maxTouchPoints > 1
const menuOptions = [
  { value: 'votes-desc', label: 'Votes Desc' },
  { value: 'votes-asc', label: 'Votes Asc' },
  { value: 'date-desc', label: 'Date Desc' },
  { value: 'date-asc', label: 'Date Asc' },
]

const ShowSongsList = () => {
  const { songSlug } = useParams<{ songSlug: string }>()
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const email = useAppSelector((state) => state.auth.user.email)
  const history = useHistory()

  const [
    getShowSongs,
    {
      data: { items, page, total_pages, order },
      loaded,
      loading,
    },
    setState,
  ] = useMakeRequest<{
    items: ListShowSongType[]
    page: number
    total_pages: number
    order: string
  }>({
    items: [],
    page: 1,
    total_pages: 1,
    order: 'votes-desc',
  })

  useEffect(() => {
    getShowSongs({
      url: `/api/show_songs/list/${songSlug}?page=${page}&order=${order}`,
    })
  }, [songSlug, getShowSongs, page, order])

  const changePage = (_e: React.ChangeEvent<unknown>, page: number) => {
    setState((state) => ({ ...state, data: { ...state.data, page } }))
  }

  const changeOrder = (e: SelectChangeEvent) =>
    setState((state) => ({
      ...state,
      data: { ...state.data, order: e.target.value as string },
    }))

  const toAddVersion = () => {
    if (email) {
      history.push(`/song/${songSlug}/add-version`)
    } else {
      dispatch(openAuthDialog())
    }
  }

  return (
    <div>
      <Helmet>
        {loaded && (
          <meta
            name="description"
            content={
              items.length
                ? `phinest versions: ${items
                    .map(
                      (i) =>
                        `${i.show.date} - ${getShowLocation({
                          city: i.show.city,
                          state: i.show.state,
                          country: i.show.country,
                        })}`,
                    )
                    .join(', ')}`
                : 'Add your favorite version!'
            }
          />
        )}
      </Helmet>
      <div className={classes.addVersionContainer}>
        {loaded ? (
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            onClick={toAddVersion}
            title={!email ? 'You must be logged in to add a version.' : ''}
            disableElevation
          >
            Add version
          </Button>
        ) : (
          <Skeleton variant="rectangular" width={126} height={37} />
        )}
        <FormControl sx={{ marginLeft: 'auto' }}>
          <InputLabel id="version-list-sort">Sort</InputLabel>
          <Select
            labelId="version-list-sort"
            value={order}
            label="Sort"
            size="small"
            onChange={changeOrder}
            native={isTouch}
          >
            {isTouch
              ? menuOptions.map((o) => (
                  <option key={o.value} value={o.value}>
                    {o.label}
                  </option>
                ))
              : menuOptions.map((o) => (
                  <MenuItem key={o.value} value={o.value}>
                    {o.label}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </div>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {loaded && !loading
          ? items.map((shs) => {
              return (
                <li key={shs.id}>
                  <ListItem
                    className={classes.item}
                    sx={{ borderRadius: 1 }}
                    component={Link}
                    disablePadding
                    to={`/song/${shs.song.slug}/${shs.show.slug}`}
                  >
                    <Paper elevation={1} className={classes.itemContent}>
                      <Box className={classes.date}>{shs.show.date}</Box>
                      <Box className={classes.showTitle}>
                        <Box className={classes.showTitleContent}>
                          {shs.show.venue}
                        </Box>
                        <Box
                          sx={{ fontStyle: 'italic' }}
                          className={classes.showTitleContent}
                        >
                          {getShowLocation({
                            city: shs.show.city,
                            state: shs.show.state,
                            country: shs.show.country,
                          })}
                        </Box>
                      </Box>
                      <Box className={classes.votes}>
                        {shs.upvote_count}
                        <ThumbUp />
                      </Box>
                    </Paper>
                  </ListItem>
                </li>
              )
            })
          : Array.from({ length: 25 }, (v, i) => {
              return (
                <Skeleton
                  key={i}
                  variant="rectangular"
                  className={classes.skeletonItem}
                />
              )
            })}
      </List>
      {total_pages > 1 && (
        <Box className={classes.paginationContainer}>
          <Pagination
            size="small"
            count={total_pages}
            page={page}
            onChange={changePage}
          />
        </Box>
      )}
      {loaded && !items.length && <NoResults>No Versions</NoResults>}
    </div>
  )
}

export default ShowSongsList

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '15px',
    },
  },
  skeletonItem: {
    display: 'flex',
    marginBottom: '20px',
    borderRadius: '4px',
    height: '64px',
    [theme.breakpoints.down('sm')]: {
      height: '50px',
      marginBottom: '15px',
    },
  },
  itemContent: {
    display: 'flex',
    minHeight: '64px',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      minHeight: '50px',
    },
  },
  date: {
    padding: '0 20px',
    color: '#2196f3',
    fontSize: '24px',
    flexFlow: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(33,150,243, 0.05)',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      padding: '0 10px',
    },
  },
  votes: {
    marginLeft: 'auto',
    minWidth: '106px',
    fontSize: '28px',
    color: '#f50057',
    backgroundColor: 'rgba(245,0,87, 0.05)',
    // border: '.5px solid #f50057',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    padding: '0 20px',
    flexFlow: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& svg': {
      marginLeft: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
      padding: '0 10px',
      minWidth: '80px',
    },
  },
  showTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '10px',
  },
  showTitleContent: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  addVersionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(3, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 0),
    },
  },
  addVersionLink: {
    textDecoration: 'none',
  },
}))
