import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import HelpCenterIcon from '@mui/icons-material/HelpCenter'
import youtube from 'media/youtube.svg'
import spotify from 'media/spotify.svg'
import Phishin from 'media/phishin_white.png'
import Relisten from 'media/relisten.png'
import { SourceType } from 'components/showsong/Sources'

const SourceIcon = ({ type }: { type: SourceType['type'] }) => {
  const classes = useStyles()
  if (type === 'youtube')
    return <img src={youtube} alt="youtube logo" className={classes.image} />
  if (type === 'spotify')
    return <img src={spotify} alt="spotify logo" className={classes.image} />
  if (type === 'phishin')
    return <img src={Phishin} alt="phish.in logo" className={classes.image} />
  if (type === 'relisten')
    return (
      <img
        src={Relisten}
        style={{ borderRadius: '3px' }}
        alt="relisten logo"
        className={classes.image}
      />
    )
  return <HelpCenterIcon className={classes.icon} />
}

const useStyles = makeStyles((theme) => ({
  icon: {
    height: '25px',
    width: 'auto',
    marginRight: '10px',
  },
  image: {
    height: 'auto',
    width: '25px',
    marginRight: '10px',
  },
}))

export default SourceIcon
