import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import { formatDistanceToNow } from 'date-fns'
import { Box, Typography, Button, Link } from '@mui/material'
import { CommentType } from './Comments'
import AddComment from './AddComment'

interface CommentProps extends CommentType {
  show_song_id: number | null
  setCommentMode: React.Dispatch<React.SetStateAction<null | number>>
  commentMode: number | null
  toAddComment: (id: CommentType['id']) => void
  setComments: React.Dispatch<
    React.SetStateAction<{
      loading: boolean
      loaded: boolean
      data: CommentType[]
      error: {}
    }>
  >
}

const Comment = ({
  id,
  message,
  inserted_at,
  user,
  children,
  show_song_id,
  setCommentMode,
  commentMode,
  toAddComment,
  setComments,
}: CommentProps) => {
  const classes = useStyles()

  const renderCommentBody = ({
    id,
    user,
    inserted_at,
    message,
    parent,
  }: {
    id: number
    user: { username: string; id: number }
    inserted_at: string
    message: string
    parent?: boolean
  }) => {
    return (
      <Box key={id} className={parent ? '' : classes.commentBody}>
        <Box className={classes.commentHeader}>
          <Link
            component={RouterLink}
            to={`/user/${user.id}`}
            className={classes.userLink}
          >
            {user.username}
          </Link>
          <Typography>
            {formatDistanceToNow(new Date(`${inserted_at}Z`), {
              addSuffix: true,
            })}
          </Typography>
        </Box>
        <Typography className={classes.comment}>{message}</Typography>
      </Box>
    )
  }

  return (
    <Box className={classes.commentContainer}>
      {renderCommentBody({
        id,
        user,
        inserted_at,
        message,
        parent: true,
      })}
      {commentMode === id ? (
        <AddComment
          setCommentMode={setCommentMode}
          show_song_id={show_song_id}
          parent_id={id}
          setComments={setComments}
        />
      ) : (
        <Box>
          <Button
            className={classes.replyButton}
            onClick={() => toAddComment(id)}
            size="small"
          >
            reply
          </Button>
        </Box>
      )}
      <Box className={classes.childrenContainer}>
        {children.map(renderCommentBody)}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  commentContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '5px 0 10px',
  },
  commentBody: {
    marginTop: '10px',
  },
  comment: {
    fontSize: '14px',
  },
  commentHeader: {
    display: 'flex',
    '& p': {
      fontSize: '12px',
      color: 'rgba(255, 255, 255, 0.5)',
    },
  },
  userLink: {
    marginRight: '10px',
    fontSize: '12px',
    color: 'rgba(255, 255, 255, 0.4)',
    textDecorationColor: 'rgba(255,255,255, 0.4)',
    '&:hover': {
      color: 'rgba(255,255,255, 0.6)',
      textDecorationColor: 'rgba(255,255,255, 0.6)',
    },
  },
  childrenContainer: {
    marginLeft: '25px',
  },
  replyButton: {
    paddingLeft: '0',
    paddingRight: '0',
    color: 'rgba(33, 150, 243, .5)',
    '&:hover': {
      color: '#2196f3',
    },
  },
}))

export default Comment
