import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import { PageContainer, BackButton } from 'components/common'
import { Typography, Skeleton, Box } from '@mui/material'
import { useMakeRequest } from 'hooks'
import isEmpty from 'ramda/src/isEmpty'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ProfileVotes from './ProfileVotes'
import ProfileComments from './ProfileComments'

interface UserProfileType {
  username: string
}

const Profile = () => {
  const classes = useStyles()
  const { user_id } = useParams<{ user_id: string }>()
  const [
    getUserProfile,
    {
      data: { username },
      loaded,
      error,
    },
  ] = useMakeRequest<UserProfileType>({
    username: '',
  })

  useEffect(() => {
    getUserProfile({
      url: `/api/users/profile/${user_id}`,
    })
  }, [user_id, getUserProfile])

  return (
    <PageContainer>
      <BackButton />
      {!isEmpty(error) ? (
        <Typography className={classes.name} variant="h5">
          Error: there was a problem finding the user profile.
        </Typography>
      ) : (
        <>
          <Box className={classes.profileContainer}>
            <AccountCircleIcon className={classes.icon} />
            <Typography className={classes.name} variant="h3">
              {loaded ? username : <Skeleton width={300} />}
            </Typography>
          </Box>
          <ProfileVotes />
          <ProfileComments />
        </>
      )}
    </PageContainer>
  )
}

const useStyles = makeStyles((theme: any) => ({
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
  },
  name: {
    fontSize: '2.5em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '3em',
    },
    fontWeight: theme.typography.fontWeightRegular,
  },
  icon: {
    fontSize: '2.5em',
    marginRight: '5px',
    fill: 'rgba(255,255,255, 0.2)',
    [theme.breakpoints.up('sm')]: {
      fontSize: '3em',
    },
  },
}))

export default Profile
