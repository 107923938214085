import { useEffect } from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import makeStyles from '@mui/styles/makeStyles'
import {
  Typography,
  Skeleton,
  Box,
  Stack,
  Paper,
  Link,
  Pagination,
} from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ThumbUpOutlined from '@mui/icons-material/ThumbUpOutlined'
import { NoResults } from 'components/common'
import { useMakeRequest } from 'hooks'

interface ProfileVoteItemType {
  id: number
  username: string
  inserted_at: string
  show: {
    date: string
    title: string
    slug: string
  }
  song: {
    name: string
    slug: string
  }
}
interface ProfileVotesRespType {
  items: ProfileVoteItemType[]
  page: number
  total_pages: number
}

const ProfileVotes = () => {
  const classes = useStyles()
  const { user_id } = useParams<{ user_id: string }>()
  const [
    getUserVotes,
    {
      data: { items, page, total_pages },
      loaded,
      loading,
    },
    setState,
  ] = useMakeRequest<ProfileVotesRespType>({
    items: [],
    page: 1,
    total_pages: 1,
  })

  useEffect(() => {
    getUserVotes({
      url: `/api/users/profile-votes/${user_id}?page=${page}`,
    })
  }, [user_id, page, getUserVotes])

  const changePage = (_e: React.ChangeEvent<unknown>, page: number) => {
    setState((state) => ({ ...state, data: { ...state.data, page } }))
  }

  const VoteItem = ({ inserted_at, show, song }: ProfileVoteItemType) => {
    const insertedTimeFormatted = format(new Date(`${inserted_at}Z`), 'PPpp')
    return (
      <li>
        <Paper elevation={1} className={classes.paper}>
          <Box className={classes.iconContainer}>
            <ThumbUpOutlined color="secondary" />
          </Box>
          <Box className={classes.voteItemBody}>
            <Box className={classes.songShow}>
              <Link
                to={`/song/${song.slug}`}
                component={RouterLink}
                color="primary"
                className={classes.songLink}
              >
                <Typography className={classes.song}>{song.name}</Typography>
              </Link>
              <Typography>&nbsp;-&nbsp;</Typography>
              <Link
                to={`/song/${song.slug}/${show.slug}`}
                color="secondary"
                component={RouterLink}
                className={classes.showLink}
              >
                <Typography>{show.date}</Typography>
              </Link>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography className={classes.showTitle}>
                {show.title}
              </Typography>
              <Box
                className={classes.dateContainer}
                title={insertedTimeFormatted}
              >
                <AccessTimeIcon className={classes.insertedIcon} />
                <Typography className={classes.insertedTime}>
                  {insertedTimeFormatted}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      </li>
    )
  }

  return (
    <Box className={classes.votesContainer}>
      <Box sx={{ display: 'flex', marginBottom: '10px' }}>
        <Typography variant="h5">Votes</Typography>
      </Box>
      <Stack
        component="ul"
        spacing={1.5}
        sx={{ listStyleType: 'none', margin: '0', padding: '0' }}
      >
        {loaded && !loading
          ? items.map((d) => <VoteItem key={d.id} {...d} />)
          : Array.from({ length: 10 }, (v, i) => {
              return (
                <Skeleton
                  key={i}
                  variant="rectangular"
                  className={classes.skeletonItem}
                />
              )
            })}
        {loaded && !items.length && <NoResults>No Votes</NoResults>}
      </Stack>
      {total_pages > 1 && (
        <Box className={classes.paginationContainer}>
          <Pagination
            size="small"
            count={total_pages}
            page={page}
            onChange={changePage}
          />
        </Box>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme: any) => ({
  votesContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  skeletonItem: {
    display: 'flex',
    borderRadius: '4px',
    height: '52px',
  },
  songLink: {
    display: 'flex',
  },
  showLink: {
    display: 'flex',
  },
  paper: {
    display: 'flex',
    flex: 1,
    padding: '5px 10px',
    minWidth: '0',
  },
  voteItemBody: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minWidth: '0',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  },
  songShow: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '30px',
    '& p': {
      whiteSpace: 'nowrap',
    },
  },
  song: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  showTitle: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '12px',
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
    minWidth: '20px',
    flex: '1',
    justifyContent: 'flex-end',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  insertedIcon: {
    fontSize: '14px',
    marginRight: '3px',
    fill: 'rgba(255,255,255, 0.5)',
  },
  insertedTime: {
    fontSize: '12px',
    fontStyle: 'italic',
    color: 'rgba(255,255,255, 0.5)',
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

export default ProfileVotes
