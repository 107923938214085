import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { Button, Box, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAppSelector, useMakeRequest } from 'hooks'

const ChangeEmail = () => {
  const classes = useStyles()
  const user = useAppSelector((state) => state.auth.user)

  const [email, setEmail] = useState(user.email)
  const [password, setPassword] = useState('')
  const [editEmail, setEditEmail] = useState(false)

  const [updateEmail, { loading: updateEmailLoading }] = useMakeRequest(null)
  const emailInputRef = useRef<HTMLInputElement>(null)

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value)
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value)

  useEffect(() => {
    if (editEmail) {
      emailInputRef.current?.focus()
    }
  }, [editEmail])

  const submitEmail = async (e: React.FormEvent) => {
    try {
      e.preventDefault()
      await updateEmail({
        method: 'put',
        url: '/api/users/update-email',
        data: {
          email,
          password,
        },
      })
      setEditEmail(false)
    } catch (e) {}
  }

  return (
    <Box
      component="form"
      onSubmit={submitEmail}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '30px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          id="email"
          label={editEmail ? 'New Email' : 'Email'}
          onChange={handleEmailChange}
          value={editEmail ? email : user.email}
          type="email"
          margin="normal"
          inputRef={emailInputRef}
          inputProps={{ className: classes.input }}
          autoComplete="email"
          variant="outlined"
          disabled={!editEmail}
          size="small"
          className={classes.textField}
          color="secondary"
        />
        {!editEmail && (
          <Button
            sx={{ ml: '5px' }}
            color="primary"
            onClick={() => setEditEmail(true)}
          >
            Edit
          </Button>
        )}
      </Box>
      {editEmail && (
        <Box sx={{ mt: '15px', display: 'flex', alignItems: 'center' }}>
          <TextField
            id="password"
            label="Current Password"
            type="password"
            onChange={handlePasswordChange}
            value={editEmail ? password : ''}
            inputProps={{ className: classes.input }}
            margin="normal"
            autoComplete="current-password"
            variant="outlined"
            size="small"
            disabled={!editEmail}
            className={classes.textField}
          />
          <Box sx={{ ml: '5px', display: 'flex', alignItems: 'center' }}>
            <Button color="neutral" onClick={() => setEditEmail(false)}>
              Cancel
            </Button>
            <LoadingButton
              color="secondary"
              type="submit"
              loading={updateEmailLoading}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme?: any) => ({
  input: {
    '&:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 100px #3a3a3a inset',
    },
  },
  textField: {
    marginTop: 0,
    marginBottom: 0,
    '& input.Mui-disabled': {
      color: '#D6D5CB',
      '-webkit-text-fill-color': '#D6D5CB',
    },
  },
}))

export default ChangeEmail
