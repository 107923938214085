import React from 'react'
import { Dialog } from '@mui/material'
import Login from './Login'
import Register from './Register'
import ForgotPassword from './ForgotPassword'
import { closeAuthDialog } from 'reducers/auth'
import { useAppDispatch, useAppSelector } from 'hooks'

const AuthDialog = () => {
  const dispatch = useAppDispatch()
  const { loginDialogOpen, view } = useAppSelector((state) => state.auth)

  const renderView = () => {
    switch (view) {
      case 'login':
        return <Login />
      case 'register':
        return <Register />
      case 'forgot-password':
        return <ForgotPassword />
    }
  }

  return (
    <Dialog
      onClose={() => dispatch(closeAuthDialog())}
      open={loginDialogOpen}
      sx={{ width: '100%' }}
    >
      {renderView()}
    </Dialog>
  )
}

export default AuthDialog
