import React from 'react'
import { LoadingButton } from '@mui/lab'
import { useAppSelector, useMakeRequest } from 'hooks'

const ResendConfirmation = () => {
  const user = useAppSelector((state) => state.auth.user)
  const [makeRequest, { loading }] = useMakeRequest(null)
  const resendConfirmation = () => {
    makeRequest({
      method: 'post',
      url: '/api/users/resend-confirmation',
      data: {
        user,
      },
    })
  }

  return (
    <LoadingButton
      sx={{ marginTop: '20px' }}
      onClick={resendConfirmation}
      color="secondary"
      variant="outlined"
      loading={loading}
    >
      Resend Confirmation Email
    </LoadingButton>
  )
}

export default ResendConfirmation
