import React, { useEffect, Suspense } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  Theme,
} from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { Snackbar, Box, LinearProgress, CssBaseline } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { Helmet } from 'react-helmet'
import TopBar from 'components/topbar/TopBar'
import Song from 'components/song/SongRouter'
import Home from 'components/home/Home'
import AuthDialog from './auth/AuthDialog'
import ConfirmAccount from './auth/ConfirmAccount'
import UpdateEmail from './auth/UpdateEmail'
import ResetPassword from './auth/ResetPassword'
import { useAppSelector, useAppDispatch, useMakeRequest } from 'hooks'
import { closeToast } from 'reducers/globalToast'
import { getUserInfoSuccess } from 'reducers/auth'
import { ProtectedRoute, AllSongsQueryRoute, AdminRoute } from './common'
import type { UserType } from 'reducers/auth'
import Settings from 'components/settings/Settings'
import Profile from 'components/profile/Profile'
import AllSongs from './home/AllSongs'
import ScrollToTop from './ScrollToTop'
import './App.css'
const Admin = React.lazy(() => import('components/admin/Admin'))

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary']
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true
  }
}

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2196f3', //rgb(33, 150, 243)
      // main: '#0CA6F3',
      // main: '#4fc2f7',
    },
    secondary: {
      main: '#f50057', //rgb(245, 0, 87)
      // main: '#E91E63',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
})

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

function App() {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const globalToast = useAppSelector((state) => state.globalToast)
  const userLoaded = useAppSelector((state) => state.auth.userLoaded)
  const [makeRequest] = useMakeRequest(null)

  useEffect(() => {
    const getUserInfo = async () => {
      const user = await makeRequest<UserType>({
        url: '/api/users/info',
      })
      dispatch(getUserInfoSuccess(user))
    }
    getUserInfo()
  }, [dispatch, makeRequest])

  const renderApp = () => {
    if (!userLoaded) return <LinearProgress />
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Box className={classes.appContainer}>
          <Helmet>
            <title>phinest</title>
            <meta
              name="description"
              content="Explore the best live versions of Phish tunes"
            />
          </Helmet>
          <Box className={classes.contentContainer}>
            <TopBar />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/song/:songSlug" component={Song} />
              {/* <Route
            path="/show/:showSlug"
            render={(props) => <div>{props.match.params.showSlug}</div>}
          /> */}
              <AllSongsQueryRoute path="/all-songs" component={AllSongs} />
              <ProtectedRoute
                path="/account-settings"
                redirectPath="/"
                component={Settings as React.ComponentType}
              />
              <Route path="/user/confirm/:token" component={ConfirmAccount} />
              <Route path="/user/update-email/:token" component={UpdateEmail} />
              <Route
                path="/user/reset-password/:token"
                component={ResetPassword}
              />
              <Route path="/user/:user_id" component={Profile} />
              <AdminRoute
                path="/admin"
                component={Admin as React.ComponentType}
              />
              <Redirect to="/" />
            </Switch>
          </Box>
          <Box className={classes.footer}>
            <a href="mailto:admin@phine.st">contact</a>
          </Box>
        </Box>
      </Suspense>
    )
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {renderApp()}
          <Snackbar
            open={globalToast.isOpen}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={() => dispatch(closeToast())}
          >
            <Alert
              onClose={() => dispatch(closeToast())}
              severity={globalToast.type}
              sx={{ width: '100%' }}
            >
              {globalToast.message}
            </Alert>
          </Snackbar>
          <AuthDialog />
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  )
}

const useStyles = makeStyles((theme?: any) => ({
  appContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  contentContainer: {
    flex: '1 0 auto',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0 10px',
    flexShrink: 0,
    fontSize: '11px',
    '& a': {
      textDecoration: 'none',
      color: 'white',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}))

export default App
