import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

interface NoResultsProps {
  children: React.ReactNode
}

const NoResults = ({ children }: NoResultsProps) => {
  const classes = useStyles()
  return <Typography className={classes.noResults}>{children}</Typography>
}

const useStyles = makeStyles(() => ({
  noResults: {
    fontStyle: 'italic',
    color: 'rgba(255,255,255, 0.5)',
  },
}))

export default NoResults
