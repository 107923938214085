import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { LoadingButton } from '@mui/lab'
import {
  Typography,
  Skeleton,
  Box,
  Button,
  TextField,
  ListItem,
  Paper,
  List,
} from '@mui/material'
import { useMakeRequest, useAppSelector, useAppDispatch } from 'hooks'
import { openAuthDialog } from 'reducers/auth'
import AddIcon from '@mui/icons-material/Add'
import { ShowSongType } from './ShowSong'
import { SourceIcon, NoResults } from 'components/common'
export interface SourceType {
  id: number
  uri: string
  type: string
}

interface SourcesProps {
  show_song_id: ShowSongType['show_song_id']
}

const Sources = ({ show_song_id }: SourcesProps) => {
  const classes = useStyles()
  const [showAddSource, setShowAddSource] = useState(false)
  const [uri, setURI] = useState('')
  const dispatch = useAppDispatch()
  const { email, id } = useAppSelector((state) => state.auth.user)
  const [submitSource, { loading: submittingSource }] =
    useMakeRequest(undefined)
  const [getSources, { data: sources, loaded }, setSources] = useMakeRequest<
    SourceType[]
  >([])

  useEffect(() => {
    if (show_song_id) {
      getSources({ url: `/api/show_songs/${show_song_id}/sources` })
    }
  }, [show_song_id, getSources])
  const toAddSource = () => {
    if (email) {
      setShowAddSource(true)
    } else {
      dispatch(openAuthDialog())
    }
  }

  const cancelAddSource = () => {
    setURI('')
    setShowAddSource(false)
  }

  const submit = async () => {
    const res = await submitSource<SourceType>({
      method: 'post',
      url: '/api/show_songs/sources/new',
      data: {
        uri: uri,
        show_song_id: show_song_id,
        user_id: id,
      },
    })
    if (typeof (res as SourceType).id !== 'undefined') {
      setSources((state) => ({ ...state, data: [...state.data, res] }))
      setURI('')
      setShowAddSource(false)
    }
  }

  const renderSourceItem = (source: SourceType) => {
    return (
      <ListItem
        key={source.id}
        className={classes.item}
        sx={{ borderRadius: 1 }}
        component="a"
        disablePadding
        href={source.uri}
        target="_blank"
      >
        <Paper className={classes.itemContent}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <SourceIcon type={source.type} />
            </Box>
            <Box sx={{ wordBreak: 'break-word' }}>{source.uri}</Box>
          </Box>
        </Paper>
      </ListItem>
    )
  }

  return (
    <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        <Typography variant="h5">Sources</Typography>
      </Box>
      <Box sx={{ mt: '5px' }}>
        {showAddSource ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              id="uri"
              autoFocus
              sx={{ flex: 1 }}
              label="URI"
              size="small"
              value={uri}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setURI(e.target.value)
              }
              variant="outlined"
              color="primary"
            />
            <LoadingButton
              sx={{ ml: 1 }}
              // variant="outlined"
              loading={submittingSource}
              // size="medium"
              color="primary"
              onClick={submit}
              disableElevation
            >
              Submit
            </LoadingButton>
            <Button
              sx={{ ml: 1 }}
              // variant="outlined"
              // size="medium"
              color="neutral"
              onClick={() => cancelAddSource()}
              disableElevation
            >
              Cancel
            </Button>
          </Box>
        ) : (
          <Button
            // sx={{ ml: 2 }}
            // variant="outlined"
            startIcon={<AddIcon />}
            size="small"
            color="primary"
            // disabled={showAddSource}
            onClick={toAddSource}
            title={!email ? 'You must be logged in to add a source.' : ''}
            disableElevation
          >
            Add source
          </Button>
        )}
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {loaded && show_song_id
            ? sources.map(renderSourceItem)
            : Array.from({ length: 3 }, (v, i) => {
                return (
                  <Skeleton
                    key={i}
                    variant="rectangular"
                    height={45}
                    className={classes.skeletonItem}
                  />
                )
              })}
          {loaded && !sources.length && <NoResults>No Sources</NoResults>}
        </List>
      </Box>
    </Box>
  )
}

export default Sources

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    marginBottom: '10px',
  },
  skeletonItem: {
    display: 'flex',
    marginBottom: '10px',
    borderRadius: '4px',
  },
  itemContent: {
    display: 'flex',
    padding: '10px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
}))
