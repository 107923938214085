import React, { useState, useEffect } from 'react'
import { useMakeRequest, useAppSelector, useAppDispatch } from 'hooks'
import { openAuthDialog } from 'reducers/auth'
import { Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import ThumbUp from '@mui/icons-material/ThumbUp'
import ThumbDown from '@mui/icons-material/ThumbDown'
import ThumbUpOutlined from '@mui/icons-material/ThumbUpOutlined'
import { ShowSongType } from './ShowSong'
interface UpVoteProps {
  show_song_id: ShowSongType['show_song_id']
  upvote_count: ShowSongType['upvote_count']
  setShowSongVotes: (upvote_count: ShowSongType['upvote_count']) => void
}

const UpVote = ({
  show_song_id,
  upvote_count,
  setShowSongVotes,
}: UpVoteProps) => {
  const dispatch = useAppDispatch()
  const {
    email,
    id: user_id,
    confirmed_at,
  } = useAppSelector((state) => state.auth.user)
  const [hoverVote, setHoverVote] = useState(false)
  const [makeUpvote, { loading }] = useMakeRequest(null)
  const [
    fetchUserUpvote,
    {
      data: { upvoted },
      loaded: fetchedUserUpvote,
      loading: fetchingUserUpvote,
    },
    setUpvotedData,
  ] = useMakeRequest({ upvoted: false })

  useEffect(() => {
    if (user_id && show_song_id && confirmed_at) {
      fetchUserUpvote({
        url: `/api/show_songs/fetch_user_vote?show_song_id=${show_song_id}&user_id=${user_id}`,
      })
    }
  }, [fetchUserUpvote, show_song_id, user_id, confirmed_at])

  const toUpvote = async () => {
    if (email) {
      try {
        const res: { upvoted: boolean } = await makeUpvote({
          method: upvoted ? 'delete' : 'post',
          url: '/api/show_songs/upvote',
          data: {
            show_song_id,
            user_id,
          },
        })
        setShowSongVotes(upvoted ? upvote_count - 1 : upvote_count + 1)
        setUpvotedData((state) => ({ ...state, data: res }))
      } catch (e) {
        console.log(e)
      }
    } else {
      dispatch(openAuthDialog())
    }
  }

  const getVoteIcon = () => {
    if (upvoted) {
      if (hoverVote) {
        return <ThumbDown />
      }
      return <ThumbUp />
    }
    return <ThumbUpOutlined />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '10px',
      }}
    >
      <LoadingButton
        color="secondary"
        title={upvoted ? 'remove upvote' : 'upvote'}
        onClick={toUpvote}
        loading={
          !!(
            (loading || !fetchedUserUpvote || fetchingUserUpvote) &&
            confirmed_at
          )
        }
        disableElevation
        onMouseEnter={() => (upvoted ? setHoverVote(true) : null)}
        onMouseLeave={() => setHoverVote(false)}
        variant={'outlined'}
        // variant={upvoted ? 'contained' : 'outlined'}
        size="large"
        endIcon={getVoteIcon()}
      >
        <span style={{ fontSize: '26px', lineHeight: '30px' }}>
          {upvote_count}
        </span>
      </LoadingButton>
    </Box>
  )
}

export default UpVote
