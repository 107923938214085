import { configureStore } from '@reduxjs/toolkit'
import globalToast from 'reducers/globalToast'
import auth from 'reducers/auth'

export const store = configureStore({
  reducer: {
    globalToast,
    auth,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
