import { Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useParams, useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector, useMakeRequest } from 'hooks'
import { PageContainer } from 'components/common'
import { openAuthDialog, emailUpdateSuccess, UserType } from 'reducers/auth'

const UpdateEmail = () => {
  const { token } = useParams<{ token: string }>()
  const dispatch = useAppDispatch()
  const email = useAppSelector((state) => state.auth.user.email)
  const [makeRequest, { loading }] = useMakeRequest(null)
  const history = useHistory()

  const handleSubmit = () => {
    if (email) {
      confirmEmail()
    } else {
      dispatch(openAuthDialog())
    }
  }

  const confirmEmail = async () => {
    try {
      const user = await makeRequest<UserType>({
        method: 'post',
        url: '/api/users/confirm-new-email',
        data: { token },
      })
      dispatch(emailUpdateSuccess(user))
      history.push('/account-settings')
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <PageContainer>
      <Typography variant="h2">Update Email</Typography>
      <LoadingButton
        loading={loading}
        onClick={handleSubmit}
        type="submit"
        variant="contained"
        sx={{ mt: 2 }}
      >
        Confirm Email
      </LoadingButton>
    </PageContainer>
  )
}

export default UpdateEmail
