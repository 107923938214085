import React, { useEffect } from 'react'
import { useMakeRequest } from 'hooks'

import { useParams, Route, Switch } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'

import AddShowVersion from './AddShowVersion'
import ShowSong from '../showsong/ShowSong'
import Song from './Song'
import { ProtectedRoute } from 'components/common'

export interface SongType {
  artist: string
  first_played: string
  id: number | null
  name: string
  slug: string
}

const SongRouter = () => {
  const { songSlug } = useParams<{ songSlug: string }>()

  const classes = useStyles()

  const [getSong, { data: song, loaded, error }] = useMakeRequest<SongType>({
    artist: '',
    first_played: '',
    id: null,
    name: '',
    slug: '',
  })

  useEffect(() => {
    getSong({ url: `/api/songs/${songSlug}` })
  }, [songSlug, getSong])

  if (error?.type === 'error')
    return <div className={classes.error}>{error.message}</div>

  return (
    <Switch>
      <Route
        exact
        path="/song/:songSlug"
        render={(props) => <Song {...props} loaded={loaded} song={song} />}
      />
      <ProtectedRoute
        path="/song/:songSlug/add-version"
        redirectPath={`/song/${songSlug}`}
        component={AddShowVersion as React.ComponentType}
        additionalProps={{ song }}
      />
      <Route
        path="/song/:songSlug/:showSlug"
        render={(props) => (
          <ShowSong {...props} songLoaded={loaded} song={song} />
        )}
      />
    </Switch>
  )
}

export default SongRouter

const useStyles = makeStyles((theme?: any) => ({
  error: {
    margin: '10px',
  },
}))
