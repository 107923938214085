import { AnyAction } from 'redux'
import { AppDispatch } from 'store'
import { setSuccessToast } from './globalToast'

const SET_AUTH_DIALOG_OPEN = 'SET_AUTH_DIALOG_OPEN'
const SET_AUTH_DIALOG_CLOSED = 'SET_AUTH_DIALOG_CLOSED'
const SET_AUTH_DIALOG_VIEW = 'SET_AUTH_DIALOG_VIEW'
const SET_USER_INFO = 'SET_USER_INFO'
const CLEAR_USER_INFO = 'CLEAR_USER_INFO'
const SET_USER_LOADED = 'SET_USER_LOADED'
const SET_USER_AS_CONFIRMED = 'SET_USER_AS_CONFIRMED'

type ViewType = 'login' | 'register' | 'forgot-password'

export interface UserType {
  id: null | number
  email: null | string
  username: null | string
  confirmed_at: null | string
  is_admin: boolean
}
interface AuthTypes {
  loginDialogOpen: boolean
  view: ViewType
  user: UserType
  userLoaded: boolean
}

const initialState: AuthTypes = {
  loginDialogOpen: false,
  view: 'login',
  user: {
    id: null,
    email: null,
    username: null,
    confirmed_at: null,
    is_admin: false,
  },
  userLoaded: false,
}

function auth(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SET_AUTH_DIALOG_OPEN:
      return {
        ...state,
        loginDialogOpen: true,
        view: action.view || 'login',
      }
    case SET_AUTH_DIALOG_CLOSED:
      return {
        ...state,
        loginDialogOpen: false,
      }
    case SET_AUTH_DIALOG_VIEW:
      return {
        ...state,
        view: action.view,
      }
    case SET_USER_INFO:
      return {
        ...state,
        user: action.user,
      }
    case SET_USER_AS_CONFIRMED:
      return {
        ...state,
        user: {
          ...state.user,
          confirmed_at: new Date().toISOString(),
        },
      }
    case SET_USER_LOADED:
      return {
        ...state,
        userLoaded: true,
      }
    case CLEAR_USER_INFO:
      return {
        ...state,
        user: initialState.user,
      }
    default:
      return state
  }
}

export default auth

export function openAuthDialog(view?: ViewType) {
  return { type: SET_AUTH_DIALOG_OPEN, view }
}

export function closeAuthDialog() {
  return { type: SET_AUTH_DIALOG_CLOSED }
}

export function setAuthDialogView(view: ViewType) {
  return { type: SET_AUTH_DIALOG_VIEW, view }
}

export function setUserInfo(user: UserType) {
  return { type: SET_USER_INFO, user }
}

export function setUserAsConfirmed() {
  return { type: SET_USER_AS_CONFIRMED }
}

function setUserLoaded() {
  return { type: SET_USER_LOADED }
}

export function clearUserInfo() {
  return { type: CLEAR_USER_INFO }
}

export const registerSuccess = (user: UserType) => (dispatch: AppDispatch) => {
  dispatch(setUserInfo(user))
  dispatch(
    setSuccessToast(`A confirmation email has been sent to ${user.email}`),
  )
  dispatch(closeAuthDialog())
}

export const updateSuccess = (user: UserType) => (dispatch: AppDispatch) => {
  dispatch(setUserInfo(user))
  dispatch(setSuccessToast(`User updated successfully.`))
}

export const emailUpdateSuccess =
  (user: UserType) => (dispatch: AppDispatch) => {
    dispatch(setUserInfo(user))
    dispatch(setSuccessToast(`Email updated successfully.`))
  }

export const passwordUpdateSuccess =
  (user: UserType) => (dispatch: AppDispatch) => {
    dispatch(setUserInfo(user))
    dispatch(setSuccessToast(`Password updated successfully.`))
  }

export const loginSuccess = (user: UserType) => (dispatch: AppDispatch) => {
  dispatch(setUserInfo(user))
  dispatch(setSuccessToast(`Welcome, ${user.email}!`))
  dispatch(closeAuthDialog())
}

export const logoutSuccess = () => (dispatch: AppDispatch) => {
  dispatch(clearUserInfo())
}

export const getUserInfoSuccess =
  (user: UserType | null) => (dispatch: AppDispatch) => {
    if (user) {
      dispatch(setUserInfo(user))
    }
    dispatch(setUserLoaded())
  }
