import { Breadcrumbs as MUIBreadcrumbs } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

const Breadcrumbs = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles()

  return (
    <MUIBreadcrumbs
      aria-label="breadcrumbs"
      classes={{ ol: classes.breadcrumbsOl, li: classes.breadcrumbsLi }}
      separator={<NavigateNextIcon fontSize="small" />}
    >
      {children}
    </MUIBreadcrumbs>
  )
}

const useStyles = makeStyles((theme?: any) => ({
  breadcrumbsOl: {
    marginBottom: '10px',
  },
  breadcrumbsLi: {
    whiteSpace: 'nowrap',
    '&:last-child': {
      overflow: 'hidden',
    },
    '& a, p': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& a': {
      textDecoration: 'underline',
      color: 'inherit',
    },
  },
}))

export default Breadcrumbs
