import { useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Box, Link, Skeleton } from '@mui/material'
import { PageContainer } from 'components/common'
import { useMakeRequest } from 'hooks'

interface SongItem {
  name: string
  artist: string
  slug: string
}

export const songInitials = [
  '0',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
] as const

const AllSongs = () => {
  const classes = useStyles()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const initial = query.get('i')
  const [getAllSongs, { data, loading, loaded }] = useMakeRequest<SongItem[]>(
    [],
  )

  useEffect(() => {
    getAllSongs({ url: `/api/songs/all?i=${initial}` })
  }, [getAllSongs, initial])

  return (
    <PageContainer>
      <Box component="nav" className={classes.initialsContainer}>
        {songInitials.map((i) => (
          <Link
            key={i}
            className={i === initial ? classes.activeLink : classes.link}
            component={RouterLink}
            to={`/all-songs?i=${i}`}
          >
            {i}
          </Link>
        ))}
      </Box>
      <Box>
        {loaded && !loading
          ? data.map((s) => {
              return (
                <div key={s.slug} className={classes.songItemContainer}>
                  <Link component={RouterLink} to={`/song/${s.slug}`}>
                    {s.name}
                  </Link>
                  <p className={classes.artist}>by&nbsp;{s.artist}</p>
                </div>
              )
            })
          : Array.from({ length: 50 }, (v, i) => {
              return (
                <Skeleton
                  key={i}
                  variant="rectangular"
                  width={100 + Math.random() * 250}
                  className={classes.skeletonItem}
                />
              )
            })}
      </Box>
    </PageContainer>
  )
}

const useStyles = makeStyles((theme?: any) => ({
  songItemContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  artist: {
    margin: '0 5px',
    fontStyle: 'italic',
    color: 'rgba(255,255,255, 0.5)',
  },
  skeletonItem: {
    display: 'flex',
    borderRadius: '4px',
    height: '20px',
    marginBottom: '4px',
  },
  activeLink: {
    color: theme.palette.secondary.main,
    margin: '0 7px',
    fontSize: '1.75rem',
    textDecorationColor: 'rgba(245, 0, 87, 0.4)',
  },
  link: {
    margin: '0 7px',
    fontSize: '1.50rem',
  },
  initialsContainer: {
    marginBottom: '10px',
    paddingRight: '30px',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      maskImage:
        'linear-gradient(to right, transparent, black 10px, black 80%, transparent)',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
}))

export default AllSongs
