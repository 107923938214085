import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { Button, Box, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAppDispatch, useAppSelector, useMakeRequest } from 'hooks'
import { updateSuccess, UserType } from 'reducers/auth'

const EditUsername = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.auth.user)
  const [editUsername, setEditUsername] = useState(false)
  const [newUsername, setNewUsername] = useState(user.username)
  const [updateUser, { loading: updateUserLoading }] = useMakeRequest(null)
  const userNameInputRef = useRef<HTMLInputElement>(null)
  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setNewUsername(e.target.value)

  useEffect(() => {
    if (editUsername) {
      userNameInputRef.current?.focus()
    }
  }, [editUsername])

  const submitUsername = async (e: React.FormEvent) => {
    try {
      e.preventDefault()
      const user = await updateUser<UserType>({
        method: 'put',
        url: '/api/users/update-info',
        data: {
          username: newUsername,
        },
      })
      dispatch(updateSuccess(user))
      setEditUsername(false)
    } catch (e) {}
  }

  return (
    <Box
      component="form"
      onSubmit={submitUsername}
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '20px',
      }}
    >
      <TextField
        id="screen_name"
        label="Screen Name"
        type="text"
        value={editUsername ? newUsername : user.username}
        onChange={handleUsernameChange}
        color="secondary"
        inputRef={userNameInputRef}
        className={classes.textField}
        inputProps={{ className: classes.input }}
        margin="normal"
        autoComplete="nickname"
        variant="outlined"
        disabled={!editUsername}
        size="small"
      />
      <Box sx={{ ml: '5px', display: 'flex', alignItems: 'center' }}>
        {editUsername ? (
          <>
            <Button color="neutral" onClick={() => setEditUsername(false)}>
              Cancel
            </Button>
            <LoadingButton
              color="secondary"
              type="submit"
              loading={updateUserLoading}
            >
              Save
            </LoadingButton>
          </>
        ) : (
          <Button color="primary" onClick={() => setEditUsername(true)}>
            Edit
          </Button>
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme?: any) => ({
  input: {
    '&:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 100px #3a3a3a inset',
    },
  },
  textField: {
    marginTop: 0,
    marginBottom: 0,
    '& input.Mui-disabled': {
      color: '#D6D5CB',
      '-webkit-text-fill-color': '#D6D5CB',
    },
  },
}))

export default EditUsername
