import React, { useState } from 'react'
import { useHistory, Link as RouterLink } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import { Typography, TextField, Button, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useAppSelector, useMakeRequest } from 'hooks'
import ShowSearch from './ShowSearch'
import { SongType } from './SongRouter'
import { PageContainer, Breadcrumbs } from 'components/common'
import Link, { LinkProps } from '@mui/material/Link'

const initialShowState = { id: '', date: '', title: '', slug: '' }

interface LinkRouterProps extends LinkProps {
  to: string
  replace?: boolean
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
)
export interface AddShowVersionProps {
  song: SongType
}

const AddShowVersion = ({ song }: AddShowVersionProps) => {
  const classes = useStyles()
  const history = useHistory()
  const user_id = useAppSelector((state) => state.auth.user.id)
  const [show, setShow] = useState(initialShowState)
  const [addVersion, { loading }] = useMakeRequest('')

  const submitVersion = async () => {
    const res = await addVersion<{ type: string }>({
      method: 'post',
      url: '/api/show_songs/new',
      data: {
        show_id: show.id,
        song_id: song.id,
        user_id,
      },
    })
    if (res.type === 'success') {
      history.push(`/song/${song.slug}/${show.slug}`)
    }
  }

  return (
    <PageContainer>
      <Breadcrumbs>
        <LinkRouter color="inherit" to="/">
          Home
        </LinkRouter>
        <LinkRouter color="inherit" to={`/song/${song.slug}`}>
          {song.name}
        </LinkRouter>
        <Typography>Add Version</Typography>
      </Breadcrumbs>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <Typography sx={{ mb: '5px', fontSize: '32px' }} variant="h3">
          {`Add Version - ${song.name}`}
        </Typography>
        <ShowSearch setShow={setShow} />
        <TextField
          id="show-title"
          className={classes.showTitle}
          label="Location"
          value={show.title}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
          color="secondary"
          disabled
        />
        <TextField
          id="show-date"
          className={classes.showDate}
          label="Date"
          value={show.date}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
          color="secondary"
          disabled
        />
        <div className={classes.buttonContainer}>
          <Button
            onClick={() => history.push(`/song/${song.slug}`)}
            color="neutral"
            className={classes.button}
          >
            Cancel
          </Button>
          <LoadingButton
            className={classes.button}
            variant="contained"
            color="secondary"
            disabled={!show.id}
            onClick={submitVersion}
            loading={loading}
          >
            Add Version
          </LoadingButton>
        </div>
      </Box>
    </PageContainer>
  )
}

export default AddShowVersion

const useStyles = makeStyles((theme: any) => ({
  showTitle: {
    margin: theme.spacing(2, 0, 0),
    '& input.Mui-disabled': {
      color: 'white',
      '-webkit-text-fill-color': 'white',
    },
  },
  showDate: {
    margin: theme.spacing(2, 0, 0),
    '& input.Mui-disabled': {
      color: 'white',
      '-webkit-text-fill-color': 'white',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2, 0),
  },
  button: {
    margin: theme.spacing(0, 0, 0, 1),
  },
  addVersionLink: {
    textDecoration: 'none',
  },
}))
