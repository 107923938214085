export const titleCase = (string: string) => {
  return string[0].toUpperCase() + string.slice(1).toLowerCase()
}

export const getShowLocation = ({
  city,
  state,
  country,
}: {
  city: string
  state: string
  country: string
}) => {
  return `${city}, ${state ? state : country}`
}
