import React, { useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Stack, Paper, Typography, Box, Skeleton, Link } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useMakeRequest } from 'hooks'
import CommentIcon from '@mui/icons-material/Comment'
import makeStyles from '@mui/styles/makeStyles'

interface CommentItemType {
  id: number
  message: string
  inserted_at: string
  user: {
    username: string
    id: number
  }
  show: {
    date: string
    title: string
    slug: string
  }
  song: {
    name: string
    slug: string
  }
}

const RecentComments = () => {
  const classes = useStyles()
  const [getRecentComments, { data, loaded }] = useMakeRequest<
    CommentItemType[]
  >([])

  useEffect(() => {
    getRecentComments({ url: '/api/recent-comments' })
  }, [getRecentComments])

  const CommentItem = ({ message, show, song, user }: CommentItemType) => {
    return (
      <Paper component="li" elevation={1} className={classes.commentBody}>
        <Box className={classes.iconContainer}>
          <CommentIcon color="primary" />
        </Box>
        <Box className={classes.commentItemBody}>
          <Box className={classes.commentHeader}>
            <Link
              component={RouterLink}
              to={`/song/${song.slug}`}
              className={classes.songLink}
              color="primary"
            >
              <Typography>{song.name}</Typography>
            </Link>
            <Typography>&nbsp;-&nbsp;</Typography>
            <Link
              component={RouterLink}
              to={`/song/${song.slug}/${show.slug}`}
              className={classes.showLink}
              color="secondary"
            >
              <Typography className={classes.date}>{show.date}</Typography>
            </Link>
            <Link
              component={RouterLink}
              to={`/user/${user.id}`}
              className={classes.userLink}
            >
              <AccountCircleIcon
                sx={{
                  fontSize: '16px',
                  marginRight: '3px',
                  fill: 'rgba(255,255,255, 0.5)',
                }}
              />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontStyle: 'italic',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {user.username}
              </Typography>
            </Link>
          </Box>
          <Typography sx={{ fontSize: '14px' }}>{message}</Typography>
        </Box>
      </Paper>
    )
  }

  return (
    <Box
      sx={{
        padding: '15px',
        borderRadius: '10px',
        background: '#353535',
      }}
    >
      <Typography
        component="h2"
        sx={{ fontWeight: 'bold', marginBottom: '10px' }}
      >
        Recent Comments
      </Typography>
      <Stack
        component="div"
        spacing={1.5}
        sx={{ listStyleType: 'none', margin: '0', padding: '0' }}
      >
        {loaded
          ? data.map((c) => <CommentItem key={c.id} {...c} />)
          : Array.from({ length: 10 }, (v, i) => {
              return (
                <Skeleton
                  key={i}
                  variant="rectangular"
                  className={classes.skeletonItem}
                />
              )
            })}
      </Stack>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  userLink: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    marginLeft: 'auto',
    textDecorationColor: 'rgba(255,255,255, 0.4)',
    '&:hover': {
      textDecorationColor: 'white',
    },
  },
  songLink: {
    display: 'flex',
  },
  showLink: {
    display: 'flex',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
    '@media (max-width: 400px)': {
      display: 'none',
    },
  },
  skeletonItem: {
    display: 'flex',
    borderRadius: '4px',
    height: '52px',
  },
  commentBody: {
    display: 'flex',
    padding: '5px 10px',
    marginBottom: '12px',
  },
  commentHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    '& p': {
      fontSize: '14px',
    },
  },
  commentItemBody: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minWidth: '0',
  },
  date: {
    marginRight: '10px',
  },
}))

export default RecentComments
