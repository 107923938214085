import React, { useState } from 'react'
import { DialogTitle, TextField, Box, Button, ButtonBase } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { makeStyles } from '@mui/styles'
import { useAppDispatch, useMakeRequest } from 'hooks'
import { closeAuthDialog, setAuthDialogView } from 'reducers/auth'

const ForgotPassword = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const [email, setEmail] = useState('')
  const [makeRequest, { loading }] = useMakeRequest(null)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      await makeRequest({
        method: 'post',
        url: '/api/users/reset_password',
        data: { email },
      })
      dispatch(closeAuthDialog())
    } catch (e) {
      console.log(e)
    }
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value)

  return (
    <>
      <DialogTitle sx={{ pb: 0, pl: 2 }}>Forgot your password?</DialogTitle>
      <Box
        sx={{
          p: 2,
          pt: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <form onSubmit={handleSubmit}>
          <TextField
            id="email"
            label="Email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            margin="normal"
            autoComplete="email"
            // autoComplete=""
            variant="outlined"
            fullWidth
          />
          <Box sx={{ display: 'flex', m: 1, ml: 0, mt: 2 }}>
            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              sx={{ mr: 1 }}
            >
              Send Reset Email
            </LoadingButton>
            <Button color="neutral" onClick={() => dispatch(closeAuthDialog())}>
              cancel
            </Button>
          </Box>
        </form>
        <Box sx={{ display: 'flex', m: 1, mt: 2 }}>
          <ButtonBase
            focusRipple
            className={classes.logRegButton}
            onClick={() => dispatch(setAuthDialogView('login'))}
          >
            Login
          </ButtonBase>
          <span className={classes.separator}>|</span>
          <ButtonBase
            focusRipple
            className={classes.logRegButton}
            onClick={() => dispatch(setAuthDialogView('register'))}
          >
            Register
          </ButtonBase>
        </Box>
      </Box>
    </>
  )
}

const useStyles = makeStyles({
  logRegButton: {
    fontSize: '14px',
    '&:hover': {
      color: 'white',
      textDecoration: 'underline',
    },
  },
  separator: {
    margin: '0 5px',
  },
})

export default ForgotPassword
